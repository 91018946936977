import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AjudaModule } from './ajuda/ajuda.module';
import { AnonynoumsModule } from './anonynoums/anonynoums.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DemoMaterialModule } from './demo-material-module';
import { EmpresaModule } from './empresa/empresa.module';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { ProdutoModule } from './produto/produto.module';
import { SharedModule } from './shared/shared.module';
import { NgxImageZoomModule } from 'ngx-image-zoom';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

registerLocaleData(localePt, 'PT-BR');

@NgModule({
  declarations: [
    AppComponent,
    AppBlankComponent
  ],
  exports: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'imperium-log' }),
    NoopAnimationsModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    AnonynoumsModule,
    AppRoutingModule,
    DataTablesModule,
    LayoutModule,
    EmpresaModule,
    ProdutoModule,
    AjudaModule,
    HttpModule,
    JsonpModule,
    ToastrModule.forRoot(),
    NgxImageZoomModule.forRoot() 
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
