import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { ProdutoConstant } from '../../../../Constants/produto.constant';
import { TipoLocalizacaoConstant } from '../../../../Constants/tipo-localizacao.constant';
import { LocalizacaoEntity } from '../../../../cadastro/entities/localizacao.entity';
import { LocalizacaoService } from '../../../../cadastro/localizacao/localizacao.service';
import { DivisaoComponent } from '../../../../divisao/divisao.component';
import { IncluirEntradaProdutoSemNfRequestEntity } from '../../../../entities/Requests/incluir-entrada-produto-sem-nf-request.entity';
import { LocalizacaoProdutoEntity } from '../../../../entities/localizacao-produto.entity';
import { AlertService } from '../../../../shared/alert.service';
import { ComponentBase } from '../../../../shared/component-base';
import { SessionService } from '../../../../shared/session.service';
import { ProdutoService } from '../../../produto.service';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.css']
})
export class ConfiguracaoComponent extends ComponentBase implements OnInit {

  armazens: LocalizacaoEntity[] = [];
  ruas: LocalizacaoEntity[] = [];
  baias: LocalizacaoEntity[] = [];
  niveis: LocalizacaoEntity[] = [];

  produtoSelecionado: IncluirEntradaProdutoSemNfRequestEntity;
  modal: NgbModalRef;
  loadingArmazens: boolean = true;
  loadingRuas: boolean = true;
  loadingBaia: boolean = true;
  loadingNivel: boolean = true;
  loadingPosicao: boolean = true;
  tipoLocalizacaoConstant: TipoLocalizacaoConstant;
  modoConfiguracao: boolean = false;
  quantidadeProduto: number;
  automatico: boolean = true;

  localizacoes: LocalizacaoProdutoEntity[] = [];

  @ViewChild("modalConfig", { static: true }) modalConfig: any;
  @ViewChild(DivisaoComponent, { static: false }) refDivisao: DivisaoComponent;

  constructor(
    public session: SessionService,
    private produtoService: ProdutoService,
    private localizacaoService: LocalizacaoService,
    protected alert: AlertService,
    protected modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    // Preencher os dropdowns
    this.tipoLocalizacaoConstant = new TipoLocalizacaoConstant();

    this.carregarArmazens();
    this.carregarRuas();
    this.carregarBaias();
    this.carregarNiveis();

    this.localizacoes = [];
    this.localizacoes.push(new LocalizacaoProdutoEntity());
  }


  getCorStatus(status: number): string {
    return ProdutoConstant.coresStatusProduto[status - 1].name;
  }

  getTituloStatus(status: number): string {
    return ProdutoConstant.statusProduto[status].name;
  }

  removerProduto(produto: IncluirEntradaProdutoSemNfRequestEntity) {
    this.alert.confirm(`Tem certeza que deseja excluir o produto ${produto.nomeProduto}? As configurações que foram realizadas serão perdidas.`).then((val) => {
      if (val.value) {
        const produtos = this.session.produtosSelecionados;
        const idx = produtos.findIndex(x => x.idInterno === produto.idInterno);
        produtos.splice(idx, 1);
        this.session.produtosSelecionados = produtos;
      }
    });
  }

  configurar(produto: IncluirEntradaProdutoSemNfRequestEntity) {
    this.produtoSelecionado = produto;

    if (this.produtoSelecionado.localizacoes) {
      this.localizacoes = this.produtoSelecionado.localizacoes;
    }
    else {
      this.localizacoes = [];
      this.localizacoes.push(new LocalizacaoProdutoEntity());
    }

    this.abrirModalConfiguracao();
  }

  abrirModalConfiguracao() {
    this.modal = this.modalService.open(this.modalConfig, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  carregarArmazens() {
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Armazem))
      .pipe(take(1))
      .subscribe(resp => {
        this.armazens = resp;
        this.loadingArmazens = false;
      });
  }

  carregarRuas() {
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Rua))
      .pipe(take(1))
      .subscribe(resp => {
        this.ruas = resp;
        this.loadingRuas = false;
      });
  }

  carregarBaias() {
    this.baias = [];
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.BaiaImpar))
      .pipe(take(1))
      .subscribe(respPar => {
        this.baias.push(...respPar);
        this.loadingBaia = false;
      });

    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.BaiaPar))
      .pipe(take(1))
      .subscribe(respImpar => {
        this.baias.push(...respImpar);
        this.loadingBaia = false;
      });
  }

  carregarNiveis() {
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Nivel))
      .pipe(take(1))
      .subscribe(resp => {
        this.niveis = resp;
        this.loadingNivel = false;
      });
  }

  // carregarPosicoes() {
  //   this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Posicao))
  //     .pipe(take(1))
  //     .subscribe(resp => {
  //       this.niveis = resp;
  //       this.loadingNivel = false;
  //     });
  // }

  removerSelecaoLocalizacao(localizacao: LocalizacaoProdutoEntity) {
    localizacao.selecionado = false;
    this.produtoSelecionado.idLocalizacao = null;
    this.produtoSelecionado.idLocalizacao = null;
    this.produtoSelecionado.nomeArmazem = null;
    this.produtoSelecionado.nomeRua = null;
    this.produtoSelecionado.nomeBaia = null;
    this.produtoSelecionado.nomeNivel = null;
    this.produtoSelecionado.nomeAreaArmazenagem = null;
  }

  validarConfiguracoes(): boolean {

    let qtdProdutoLocalizacao = 0;
    if (!this.produtoSelecionado.quantidade) {
      this.alert.info("Informe a quantidade de entrada do produto");
      return false;
    }

    if (!this.produtoSelecionado.valorUnitario) {
      this.alert.info("Informe o valor unitário do produto");
      return false;
    }

    if (!this.produtoSelecionado.pesoProduto) {
      this.alert.info("Informe o peso do produto");
      return false;
    }

    for (let x = 0; x < this.localizacoes.length; x++) {
      if (!this.localizacoes[x].idArmazem) {
        this.alert.info("Selecione o armazem na linha " + x + 1 + " de localizações");
        return false;
      }

      if (!this.localizacoes[x].idRua) {
        this.alert.info("Selecione a Rua na linha " + x + 1 + " de localizações");
        return false;
      }

      if (!this.localizacoes[x].idBaia) {
        this.alert.info("Selecione a Baia na linha " + x + 1 + " de localizações");
        return false;
      }

      if (!this.localizacoes[x].idNivel) {
        this.alert.info("Selecione o Nivel na linha " + x + 1 + " de localizações");
        return false;
      }

      qtdProdutoLocalizacao += Number(this.localizacoes[x].qtdProduto);
    }

    if (this.localizacoes.length > 1 && qtdProdutoLocalizacao !== Number(this.produtoSelecionado.quantidade)) {
      this.alert.info("A quantidade de produtos nas localizações não bate com a quantidade total do produto");
      return false;
    }


    return true;
  }

  gravarConfiguracoes() {
    if (!this.validarConfiguracoes())
      return;

    this.produtoSelecionado.localizacoes = this.localizacoes;

    const produtos = this.session.produtosSelecionados;
    const idx = produtos.findIndex(x => x.idInterno === this.produtoSelecionado.idInterno);
    produtos[idx] = this.produtoSelecionado;

    this.session.produtosSelecionados = produtos;

    this.modal.close();
  }

  consultarImagemProduto(index: number): void {
    let produtos = this.session.produtosSelecionados;
    let produto = produtos[index];

    if (!produto.imagem) {
      this.loading2<string>(() => this.produtoService.consultarImagemProduto(produto.idInterno))
        .pipe(take(1))
        .subscribe(resp => {
          const sanitizer = this.sanitizer;
          if (resp) {
            produto.imagem = sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp);
            produtos[index] = produto;

            this.session.produtosSelecionados = produtos;
          }
        });
    }
  }

  preencherArmazem(id: any) {
    this.produtoSelecionado.nomeArmazem = this.armazens.find(x => x.idLocalizacao === id).descricao;
  }

  preencherRua(id: any) {
    this.produtoSelecionado.nomeRua = this.ruas.find(x => x.idLocalizacao === id).descricao;
  }

  preencherBaia(id: any) {
    this.produtoSelecionado.nomeBaia = this.baias.find(x => x.idLocalizacao === id).descricao;
  }

  preencherNivel(id: any) {
    this.produtoSelecionado.nomeNivel = this.niveis.find(x => x.idLocalizacao === id).descricao;
  }

  preencherPosicao(id: any) {
    this.produtoSelecionado.nomeNivel = this.niveis.find(x => x.idLocalizacao === id).descricao;
  }

  addLocalizacao() {
    this.localizacoes.push(new LocalizacaoProdutoEntity());
    //  this.atualizarQtd();
  }

  removerLocalizacao(idx: number) {
    this.localizacoes.splice(idx, 1);
    this.atualizarQtd();
  }

  atualizarQtd() {
    if (!this.automatico && this.produtoSelecionado.quantidade <= 0)
      return;

    const qtd = Math.floor(this.produtoSelecionado.quantidade / this.localizacoes.length);
    this.localizacoes.forEach(x => x.qtdProduto = qtd);

    if (this.produtoSelecionado.quantidade % this.localizacoes.length > 0) {
      this.localizacoes[0].qtdProduto = qtd + 1;

    }
  }

  permiteAlterar() {
    this.automatico = !this.automatico;
    if (this.automatico)
      this.atualizarQtd();
  }
}
