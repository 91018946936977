import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { DetalhePedidoEntity } from '../../entities/detalhe-pedido.entity';
import { HistoricoPedidoEntity } from '../../entities/historico-pedido.entity';
import { OcorrenciaPedidoEntity } from '../../entities/ocorrencia-pedido.entity';
import { PessoaEntity } from '../../entities/pessoa.entity';
import { ProdutoPedidoEntity } from '../../entities/produto-pedido.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { AlterarPedidoRequestEntity } from '../../entities/Requests/alterar-pedido-request.entity';
import { AlterarStatusPedidoRequestEntity } from '../../entities/Requests/alterar-status-pedido.entity';
import { SolicitantePedidoEntity } from '../../entities/solicitante-pedido.entity';
import { ProdutoService } from '../../produto/produto.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { PedidoService } from '../pedidos.service';

@Component({
  selector: 'app-detalhe-pedido',
  templateUrl: './detalhe-pedido.component.html',
  styleUrls: ['./detalhe-pedido.component.css']
})
export class DetalhePedidoComponent extends ComponentBase implements OnInit {
  modal: NgbModalRef;
  solicitante: SolicitantePedidoEntity;
  detalhePedido: DetalhePedidoEntity = new DetalhePedidoEntity();
  destinatario: PessoaEntity;
  produtos: ProdutoPedidoEntity[] = [];
  kits: ProdutoPedidoEntity[] = [];
  historico: HistoricoPedidoEntity[];
  ocorrencias: OcorrenciaPedidoEntity[];
  codigoPedido: string;
  tipo: string;
  semDetalhe: boolean = true;
  disponivelParaEdicao: boolean = false;
  observacao: string;
  removeuProduto: boolean = false;
  showMarca: boolean;
  permiteImprimirDeclaracao: boolean = false;

  constructor(
    protected modalService: NgbModal,
    private pedidoService: PedidoService,
    private session: SessionService,
    private activatedRoute: ActivatedRoute,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    private toast: ToastrService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.showMarca = !this.session.empresa;
    this.carregarDados();
  }

  carregarDados() {
    this.codigoPedido = this.activatedRoute.snapshot.paramMap.get('codigo');
    this.tipo = this.activatedRoute.snapshot.paramMap.get('tipo');
    this.consultarDadosPedido();
    this.consultarDestinatarioPedido();
    this.consultarSolicitantePedido();
    this.consultarHistoricoPedido();
    //this.consultarOcorrenciasPedido();
    this.consultarProdutosPedido();
    this.consultarKitsPedido();
    this.observacao = "";
    this.removeuProduto = false;

    this.permiteImprimirDeclaracao = !(!this.session.pedidoSelecionado.idDeclaracao);
  }


  getImagemMarca(produto: ProdutoPedidoEntity) {
    var empresa = this.session.usuario.empresas.find(x => x.idCliente == produto.idCliente);
    return this.session.logoClientes + empresa.nomeCliente + ".png";
  }

  consultarSolicitantePedido() {
    this.loadingGestores<SolicitantePedidoEntity>(this.pedidoService, "consultarSolicitantePedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {
        this.solicitante = resp;
      });
  }

  consultarDadosPedido() {
    this.loadingGestores<DetalhePedidoEntity>(this.pedidoService, "consultarDadosPedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {

        this.detalhePedido = resp;
        this.semDetalhe = false;
        if (!this.detalhePedido) {
          this.detalhePedido = new DetalhePedidoEntity();
          this.semDetalhe = true;
        }
        this.disponivelParaEdicao = this.tipo === 'P' && resp.codigoStatus === 2;
      });
  }

  consultarDestinatarioPedido() {
    this.loadingGestores<PessoaEntity>(this.pedidoService, "consultarDestinatarioPedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {
        this.destinatario = resp;

      });
  }



  consultarKitsPedido() {
    this.loadingGestores<ProdutoPedidoEntity[]>(this.pedidoService, "consultarKitsPedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {

        this.kits = resp.map((x) => {
          x.novaQuantidade = x.quantidadeSolicitada;
          return x;
        });
      });
  }

  consultarProdutosPedido() {
    this.loadingGestores<ProdutoPedidoEntity[]>(this.pedidoService, "consultarProdutosPedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {

        this.produtos = resp.map((x) => {
          x.novaQuantidade = x.quantidadeSolicitada;
          return x;
        });
      });
  }

  consultarHistoricoPedido() {
    this.loadingGestores<HistoricoPedidoEntity[]>(this.pedidoService, "consultarHistoricoPedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {
        this.historico = resp;
      });
  }

  consultarOcorrenciasPedido() {
    this.loadingGestores<OcorrenciaPedidoEntity[]>(this.pedidoService, "consultarOcorrenciasPedido", [this.session.pedidoSelecionado.idCliente, this.codigoPedido])
      .pipe(take(1))
      .subscribe(resp => {
        this.ocorrencias = resp;

      });
  }

  getImagemProduto(codigoProduto: string): string {
    return this.produtoService.getImagemProduto(codigoProduto);
  }

  getValorTotalProdutos(): number {
    if (this.produtos.length === 0)
      return 0;

    return this.produtos.map(item => this.disponivelParaEdicao ? (item.novaQuantidade * item.valorUnitario) : item.total).reduce((prev, next) => prev + next);
  }

  permiteAprovacao(divisao: string): boolean {
    let ret = this.session.divisoesAprovaPedido.findIndex((x) => x.nomeDivisao.toLowerCase() === divisao.toLowerCase());
    return ret > -1;
  }


  aprovarPedido() {
    if (!this.permiteAprovacao(this.detalhePedido.pessoaDivisao)) {
      this.alert.warning("Seu usuário não possui permissão para aprovar pedidos dessa divisão.")
      return;
    }

    this.alert.confirm("Tem certeza que deseja APROVAR o pedido Nº " + this.detalhePedido.codigoPedido)
      .then((result) => {
        if (result) {
          var obj = new AlterarStatusPedidoRequestEntity();
          obj.empresas = this.session.getIdsEmpresa();
          obj.codigoPedidoAntigo = this.detalhePedido.codigoPedido;
          obj.usuarioAlteracaoStatus = this.session.usuario;

          this.loadingGestores<any>(this.pedidoService, "aprovarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.alert.info(`Pedido Nº ${this.detalhePedido.codigoPedido} aprovado com sucesso.`);
              this.disponivelParaEdicao = false;
              this.detalhePedido.status = "APROVADO";
            });
        }
      });
  }

  reprovarPedido() {
    if (!this.permiteAprovacao(this.detalhePedido.pessoaDivisao)) {
      this.alert.warning("Seu usuário não possui permissão para reprovar pedidos dessa divisão.")
      return;
    }

    this.alert.confirm("Tem certeza que deseja REPROVAR o pedido Nº " + this.detalhePedido.codigoPedido)
      .then((result) => {
        if (result) {
          var obj = new AlterarStatusPedidoRequestEntity();
          obj.empresas = this.session.getIdsEmpresa();
          obj.codigoPedidoAntigo = this.detalhePedido.codigoPedido;
          obj.usuarioAlteracaoStatus = this.session.usuario;

          this.loadingGestores<any>(this.pedidoService, "reprovarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.alert.info(`Pedido Nº ${this.detalhePedido.codigoPedido} reprovado com sucesso.`);
              this.disponivelParaEdicao = false;
              this.detalhePedido.status = "REPROVADO";
            });
        }
      });
  }

  cancelarPedido() {
    if (!this.permiteAprovacao(this.detalhePedido.pessoaDivisao)) {
      this.alert.warning("Seu usuário não possui permissão para cancelar pedidos dessa divisão.")
      return;
    }

    this.alert.confirm("Tem certeza que deseja CANCELAR o pedido Nº " + this.detalhePedido.codigoPedido)
      .then((result) => {
        if (result) {
          var obj = new AlterarStatusPedidoRequestEntity();
          obj.empresas = this.session.getIdsEmpresa();
          obj.codigoPedidoAntigo = this.detalhePedido.codigoPedido;
          obj.usuarioAlteracaoStatus = this.session.usuario;

          this.loadingGestores<any>(this.pedidoService, "cancelarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.alert.info(`Pedido Nº ${this.detalhePedido.codigoPedido} cancelado com sucesso.`);
              this.disponivelParaEdicao = false;
              this.detalhePedido.status = "CANCELADO";
            });
        }
      });
  }

  removerProduto(produto: ProdutoPedidoEntity, index: number) {
    this.produtos.splice(index, 1);
    this.removeuProduto = true;
  }

  desfazerAlteracoes() {
    this.alert.confirm("Tem certeza que deseja desfazer as alterações? Essa ação não poderá ser desfeita.")
      .then(res => {
        if (res)
          this.carregarDados();
      });
  }

  addProdutos(modalDestinatario: any) {
    this.modal = this.modalService.open(modalDestinatario, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  addRemoveProduto(produto: ProdutoEntity) {
    if (produto.selecionado) {
      this.addProdutoNovo(produto);
    } else {
      this.removeProdutoNovo(produto)
    }
  }

  addProdutoNovo(produto: ProdutoEntity) {
    let idx = this.produtos.findIndex(x => x.codigoProduto === produto.codigoProduto);
    if (idx > -1) {
      this.toast.warning('Produto já existe no pedido.', 'Produtos', {
        progressBar: true,
        positionClass: 'toast-top-left'
      });
      return;
    }

    let produtoPedido = new ProdutoPedidoEntity();
    produtoPedido.idProduto = produto.idInterno;
    produtoPedido.codigoProduto = produto.codigoProduto;
    produtoPedido.nomeProduto = produto.nomeProduto;
    produtoPedido.nomePessoaDivisao = produto.nomeDivisao;
    produtoPedido.valorUnitario = produto.valorUnitario;
    produtoPedido.quantidadeSolicitada = 0;
    produtoPedido.novaQuantidade = 1;
    produtoPedido.total = produtoPedido.valorUnitario;

    this.produtos.push(produtoPedido);

    this.toast.success('Produto adicionado com sucesso.', 'Produtos', {
      progressBar: true,
      positionClass: 'toast-top-left'
    });
  }

  removeProdutoNovo(produto: ProdutoEntity) {
    let idx = this.produtos.findIndex(x => x.codigoProduto === produto.codigoProduto);
    if (idx > -1)
      this.produtos.splice(idx, 1);

    this.toast.success('Produto removido com sucesso.', 'Produtos', {
      progressBar: true,
      positionClass: 'toast-top-left'
    });
  }

  emEdicao(): boolean {
    for (let x = 0; x < this.produtos.length; x++) {
      if (this.produtos[x].quantidadeSolicitada === 0)
        return true;

      if (this.produtos[x].quantidadeSolicitada != this.produtos[x].novaQuantidade)
        return true;
    }

    return this.observacao != "" || this.removeuProduto;
  }

  salvarAlteracoes() {
    this.alert.confirm("Tem certeza que deseja salvar as alterações realizadas no pedido Nº" + this.detalhePedido.codigoPedido + "?")
      .then(res => {
        if (res) {
          let obj = new AlterarPedidoRequestEntity();
          obj.codigoPedido = this.detalhePedido.codigoPedido;
          obj.observacao = this.observacao;
          obj.produtos = this.produtos;
          obj.usuarioLogado = this.session.usuario;

          this.loadingGestores<any>(this.pedidoService, "alterarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.alert.info("Pedido alterado com sucesso.")
                .then(() => {
                  this.carregarDados();
                });
            });
        }
      });
  }

  visualizarDeclaracao() {
    this.router.navigate(['/pedidos/declaracao/' + this.session.pedidoSelecionado.idDeclaracao]);
  }
}
