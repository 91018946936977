import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent extends ComponentBase {
  public config: PerfectScrollbarConfigInterface = {};
  notifications: any[] = [];

  constructor(private session: SessionService) {
    super();
    if (!this.session.usuario.acessoNovo) {
      if (this.session.divisoes.find(x => x.produtoBaixaUtilizacao.length > 0)) {
        this.notifications = [
          {
            id: 1,
            icon: "ti-alert",
            title: "Atenção!",
            subject: "Existem um ou mais produtos que não estão sendo utilizados a mais de 90 dias.",
            round: "round-warning",
            link: "/produtos/baixa-utilizacao"
          }
        ];
      }
    }

    this.removerNotificacoes();

  }

  ocultar(not: any) {
    this.notifications.splice(this.notifications.indexOf(not), 1);
    let notificacoes = this.session.notificacoesOcultas;
    if (!notificacoes) {
      notificacoes = [];
    }
    notificacoes.push(not.id);
    this.session.notificacoesOcultas = notificacoes;
  }

  removerNotificacoes() {
    let notificacoes = this.session.notificacoesOcultas;
    if (notificacoes) {
      for (var x = 0; x < notificacoes.length; x++) {
        this.notifications.splice(this.notifications.findIndex(x => x.id == notificacoes[x]), 1);
      }
    }
  }

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];
}
