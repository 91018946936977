import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { RelatorioRecebimentosResponseEntity } from '../../entities/Responses/relatorio-recebimentos-response.entity';
import { AlertService } from '../../shared/alert.service';

@Component({
  selector: 'app-recebimentos',
  templateUrl: './recebimentos.component.html',
  styleUrls: ['./recebimentos.component.css']
})
export class RecebimentosComponent extends ComponentBase implements OnInit {

  resultado:RelatorioRecebimentosResponseEntity[] = [];

  constructor(
    protected alert:AlertService
  ) { 
    super();
  }

ngOnInit(){
  super.ngOnInit();
}

  onFilter(resultado:RelatorioRecebimentosResponseEntity[]){    
    if (resultado.length == 0){
      this.resultado = [];
      this.clearDataTable("dtRecebimentos");
      this.alert.warning("Nenhum resultado foi obtido com base nos filtros informados.");
      return;
    }

    this.resultado = resultado;
    this.refreshDataTable("dtRecebimentos");
  }

}
