import { Component, OnInit } from '@angular/core';
import { ItemCarrinhoEntity } from '../../entities/item-carrinho.entity';
import { PessoaEntity } from '../../entities/pessoa.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { ProdutoService } from '../../produto/produto.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-conferir-pedido',
  templateUrl: './conferir-pedido.component.html',
  styleUrls: ['./conferir-pedido.component.css']
})
export class ConferirPedidoComponent extends ComponentBase implements OnInit {
  showMarca: boolean;
  produtos: ItemCarrinhoEntity[] = [];
  pessoas: PessoaEntity[] = [];

  observacao: string;

  constructor(
    private carrinhoService: CarrinhoService,
    private produtoService: ProdutoService,
    private session: SessionService,
  ) { super(); }

  ngOnInit() {
    this.showMarca = !this.session.empresa;
    this.produtos = this.carrinhoService.itens;
  }


  getImagemMarca(item: ItemCarrinhoEntity) {
    var empresa = this.session.usuario.empresas.find(x => x.idCliente == item.produto.idCliente);
    return this.session.logoClientes + empresa.nomeCliente + ".png";
  }

  getImagemProduto(produto: ProdutoEntity) {
    if (produto.imagemKit)
      return produto.imagemKit;

    return this.produtoService.getImagemProduto(produto.codigoProduto);
  }

  getValorCarrinho(): number {
    return this.carrinhoService.getValorCarrinho();
  }

  getProdutosCarrinho(): ItemCarrinhoEntity[] {
    this.produtos = this.carrinhoService.itens;
    return this.carrinhoService.itens;;
  }



}
