import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoBaixaUtilizacaoResponseEntity } from './../../entities/Responses/produto-baixa-utilizacao.response.entity';

@Component({
  selector: 'app-produto-baixa-utilizacao',
  templateUrl: './produto-baixa-utilizacao.component.html',
  styleUrls: ['./produto-baixa-utilizacao.component.css']
})
export class ProdutoBaixaUtilizacaoComponent extends ComponentBase implements OnInit {
  produtos: ProdutoBaixaUtilizacaoResponseEntity[] = [];

  constructor(
    private session: SessionService
  ) {
    super();
  }

  ngOnInit() {
    for (var x = 0; x < this.session.divisoes.length; x++) {
      for (var y = 0; y < this.session.divisoes[x].produtoBaixaUtilizacao.length; y++) {
        this.produtos.push(this.session.divisoes[x].produtoBaixaUtilizacao[y]);
      }
    }
  }
}
