import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { FormsModule } from '@angular/forms';
import { MatCardModule, MatCheckboxModule, MatIconModule, MatSlideToggleModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { DataTablesModule } from 'angular-datatables';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ImgFallBackDirective } from '../directives/img-fall-back.directive';
import { OnCreateDirective } from '../directives/on-create.directive';
import { DivisaoModule } from '../divisao/divisao.module';
import { LayoutModule } from '../layout/layout.module';
import { PainelInformacaoModule } from '../painel-informacao/painel-informacao.module';
import { PipesModule } from './../pipes/pipes.module';
import { AssociaProdutoKitComponent } from './associa-produto-kit/associa-produto-kit.component';
import { CadastroProdutoEditarComponent } from './cadastro-produto/cadastro-produto-editar/cadastro-produto-editar.component';
import { CadastroProdutoComponent } from './cadastro-produto/cadastro-produto.component';
import { CardProdutoComponent } from './card-produto/card-produto.component';
import { ConfiguracaoKitProdutoFormularioComponent } from './configuracao-kit-produto/configuracao-kit-produto-formulario/configuracao-kit-produto-formulario.component';
import { ConfiguracaoKitProdutoComponent } from './configuracao-kit-produto/configuracao-kit-produto.component';
import { DetalheProdutoComponent } from './detalhe-produto/detalhe-produto.component';
import { EntradaProdutoDadosNfComponent } from './entrada-produto/entrada-produto-dados-nf/entrada-produto-dados-nf.component';
import { ConferenciaComponent } from './entrada-produto/entrada-produto-sem-nf/conferencia/conferencia.component';
import { ConfiguracaoComponent } from './entrada-produto/entrada-produto-sem-nf/configuracao/configuracao.component';
import { EntradaProdutoSemNfComponent } from './entrada-produto/entrada-produto-sem-nf/entrada-produto-sem-nf.component';
import { FinalizacaoComponent } from './entrada-produto/entrada-produto-sem-nf/finalizacao/finalizacao.component';
import { EntradaProdutoComponent } from './entrada-produto/entrada-produto.component';
import { FiltroProdutoComponent } from './filtro-produto/filtro-produto.component';
import { ProdutoBaixaUtilizacaoComponent } from './produto-baixa-utilizacao/produto-baixa-utilizacao.component';
import { ProdutoDetalheComponent } from './produto-detalhe/produto-detalhe.component';
import { ProdutoEstatisticaComponent } from './produto-estatistica/produto-estatistica.component';
import { ProdutoFacetComponent } from './produto-facet/produto-facet.component';
import { ProdutoMovimentoComponent } from './produto-movimento/produto-movimento.component';
import { ProdutoOportunidadeComponent } from './produto-oportunidade/produto-oportunidade.component';
import { ProdutoRelacionadoComponent } from './produto-relacionado/produto-relacionado.component';
import { ProdutoRoutingModule } from './produto-routing.module';
import { ProdutoSolicitacaoEstoqueGestaoComponent } from './produto-solicitacao-estoque-gestao/produto-solicitacao-estoque-gestao.component';
import { ProdutoSolicitacaoEstoqueComponent } from './produto-solicitacao-estoque/produto-solicitacao-estoque.component';
import { ProdutoTagsComponent } from './produto-tags/produto-tags.component';
import { ProdutoTransferenciaLocalComponent } from './produto-transferencia-local/produto-transferencia-local.component';
import { ProdutoTransferenciaSaldoComponent } from './produto-transferencia-saldo/produto-transferencia-saldo.component';
import { ProdutoComponent } from './produto.component';
import { SolicitacaoKitProdutoComponent } from './solicitacao-kit-produto/solicitacao-kit-produto.component';
import { TableProdutoComponent } from './table-produto/table-produto.component';
import { TableProduto2Component } from './table-produto2/table-produto2.component';
import { ProdutoAjusteEstoqueComponent } from './produto-ajuste-estoque/produto-ajuste-estoque.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    DataTablesModule,
    NgSelectModule,
    DivisaoModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    AccordionModule,
    ProdutoRoutingModule,
    RouterModule,
    PainelInformacaoModule,
    PipesModule,
    DragDropModule,
    DropzoneModule,
    MatSlideToggleModule,
    NgxImageZoomModule.forRoot() 
  ],
  declarations: [
    ProdutoComponent,
    FiltroProdutoComponent,
    CardProdutoComponent,
    TableProdutoComponent,
    DetalheProdutoComponent,
    SolicitacaoKitProdutoComponent,
    ConfiguracaoKitProdutoComponent,
    ConfiguracaoKitProdutoFormularioComponent,
    AssociaProdutoKitComponent,
    EntradaProdutoSemNfComponent,
    ConfiguracaoComponent,
    ConferenciaComponent,
    FinalizacaoComponent,
    ImgFallBackDirective,
    ProdutoEstatisticaComponent,
    ProdutoMovimentoComponent,
    ProdutoTransferenciaLocalComponent,
    EntradaProdutoDadosNfComponent,
    CadastroProdutoComponent,
    CadastroProdutoEditarComponent,
    OnCreateDirective,
    ProdutoBaixaUtilizacaoComponent,
    ProdutoDetalheComponent,
    ProdutoSolicitacaoEstoqueComponent,
    ProdutoSolicitacaoEstoqueGestaoComponent,
    ProdutoRelacionadoComponent,
    ProdutoTagsComponent,
    ProdutoFacetComponent,
    TableProduto2Component,
    ProdutoTransferenciaSaldoComponent,
    ProdutoOportunidadeComponent,
    EntradaProdutoComponent,
    ProdutoAjusteEstoqueComponent
  ],
  exports: [
    ProdutoComponent,
    SolicitacaoKitProdutoComponent,
    ConfiguracaoKitProdutoComponent,
    AssociaProdutoKitComponent,
    EntradaProdutoSemNfComponent,
    ImgFallBackDirective,
    ProdutoDetalheComponent,
    ProdutoFacetComponent,
    ProdutoOportunidadeComponent
  ]
})
export class ProdutoModule { }
