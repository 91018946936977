import { UsuarioLogadoEntity } from "../usuario-logado.entity";

export class AlterarStatusPedidoRequestEntity {
  usuarioAlteracaoStatus: UsuarioLogadoEntity;
  codigoPedidoAntigo: number;
  empresas: string;
  pesoCubado: number;
  observacao: string;
  status: string;
  codigoPedido: number;
  situacaoPedido: number;
}
