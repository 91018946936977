import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { ServiceBase } from "../../shared/service-base";
import { PessoaEnderecoEntity } from '../entities/pessoa-endereco.entity';

@Injectable({
  providedIn: 'root'
})
export class PessoaService extends ServiceBase {

  constructor(
    protected http?: HttpClient,
  ) { super(); }

  get basePessoa(): string {
    if (window.location.href.indexOf('depositante') > -1)
      return "depositante";

    if (window.location.href.indexOf('fornecedor') > -1)
      return "fornecedor";

    if (window.location.href.indexOf('destinatario') > -1) {
      return "destinatario";
    }

    return "pessoa";
  }

  consultarEnderecos(id: number): Observable<PessoaEnderecoEntity[]> {
    return this.http.get<any>(`${environment.baseUrl}api/v2/pessoa/endereco/${id}`);
  }
}
