import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { RelatorioEntity } from '../../entities/relatorio.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { RelatorioService } from '../relatorios.service';
import { ConfigurarRelatorioFormComponent } from './configurar-relatorio-form/configurar-relatorio-form.component';

@Component({
  selector: 'app-configurar-relatorio',
  templateUrl: './configurar-relatorio.component.html',
  styleUrls: ['./configurar-relatorio.component.css']
})
export class ConfigurarRelatorioComponent extends ComponentBase implements OnInit {

  showIncluirAlterar: boolean = false;
  relatorios: RelatorioEntity[];
  relatorioSelecionado: RelatorioEntity = new RelatorioEntity();

  @ViewChild(ConfigurarRelatorioFormComponent, { static: true }) configurarRelatorioFormRef: ConfigurarRelatorioFormComponent;

  constructor(
    private relatorioService: RelatorioService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.listarRelatorios();
  }

  listarRelatorios() {
    this.loading2<RelatorioEntity[]>(() => this.relatorioService.listarRelatorios())
      .pipe(take(1))
      .subscribe(resp => {
        this.relatorios = resp;
        this.refreshDataTable("dtRelatorios");
      });
  }

  excluirRelatorio(relatorio: RelatorioEntity, idx: number) {
    this.alert.confirm(`Tem certeza que deseja excluir o relatório ${relatorio.nomeRelatorio}? Essa ação não poderá ser desfeita.`)
      .then(val => {
        if (val.value) {
          this.loading2<void>(() => this.relatorioService.excluirRelatorio(relatorio.codigoRelatorio))
            .pipe(take(1))
            .subscribe(() => {
              this.alert.success("Relatório excluído com sucesso.");
              this.relatorios.splice(idx, 1);
              this.refreshDataTable("dtRelatorios");
            });
        }
      })
  }

  salvar() {
    this.showIncluirAlterar = false;
    this.listarRelatorios();
  }

  cancelar() {
    this.showIncluirAlterar = false;
  }

  inserirRelatorio() {
    this.relatorioSelecionado = new RelatorioEntity();
    this.showIncluirAlterar = true;
  }

  alterarRelatorio(relatorio: RelatorioEntity) {

    this.relatorioSelecionado = relatorio;
    if (relatorio.filtros)
      this.configurarRelatorioFormRef.filtros = relatorio.filtros;
    this.showIncluirAlterar = true;
  }
}
