import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { take } from 'rxjs/operators';
import { KeyValueEntity } from '../../../entities/key-value.entity';
import { RelatorioEnvioRequestEntity } from '../../../entities/Requests/relatorio-envio-request.entity';
import { RelatorioEnvioResponseEntity } from '../../../entities/Responses/relatorio-envio-response.entity';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';
import { RelatorioService } from '../../relatorios.service';


@Component({
  selector: 'app-filtro-envios',
  templateUrl: './filtro-envios.component.html',
  styleUrls: ['./filtro-envios.component.css']
})
export class FiltroEnviosComponent extends ComponentBase implements OnInit {
  divisoesSelecionadas: number[];
  consulta: RelatorioEnvioRequestEntity;
  @Output() onFilter: EventEmitter<RelatorioEnvioResponseEntity[]> = new EventEmitter();
  agrupamentos: KeyValueEntity[] = [];

  constructor(
    protected session: SessionService,
    private relatorioService: RelatorioService,
  ) {
    super();
  }

  ngOnInit() {
    this.consulta = new RelatorioEnvioRequestEntity();
    this.carregarAgrupamentos();
  }

  carregarAgrupamentos() {
    let solicitante = new KeyValueEntity();
    let destinatario = new KeyValueEntity();
    let ambos = new KeyValueEntity();

    solicitante.key = 1;
    solicitante.value = "Solicitante";

    destinatario.key = 2;
    destinatario.value = "Destinatário";

    ambos.key = 3;
    ambos.value = "Ambos";

    this.agrupamentos.push(solicitante);
    this.agrupamentos.push(destinatario);
    this.agrupamentos.push(ambos);
  }

  limpar() {
    this.consulta = new RelatorioEnvioRequestEntity();
  }

  pesquisar() {
    if (this.divisoesSelecionadas == null || this.divisoesSelecionadas.length == 0)
      this.consulta.divisoes = this.session.getIdDivisoes();
    else
      this.consulta.divisoes = this.divisoesSelecionadas;

    this.consulta.cliente = this.session.usuario['idCliente'];
    this.loadingGestores<RelatorioEnvioResponseEntity[]>(this.relatorioService, "relatorioEnvios", [this.consulta])
      .pipe(take(1))
      .subscribe(resp => {

        this.onFilter.emit(resp);
      });
  }

}
