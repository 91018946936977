import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { IndicadorEntity } from '../entities/indicador-entity';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { IndicadorService } from './indicador.service';

@Component({
  selector: 'app-indicador',
  templateUrl: './indicador.component.html',
  styleUrls: ['./indicador.component.css']
})
export class IndicadorComponent extends ComponentBase implements OnInit {
  tooltip: any;
  cap: any[];
  needleTail: any[];
  indicadores: IndicadorEntity[];

  constructor(
    private indicadorService: IndicadorService,
    private session: SessionService
  ) {
    super();
  }

  ngOnInit() {
    this.tooltip = {
      enable: true
    };

    this.loadingGestores<IndicadorEntity[]>(this.indicadorService, "consultarIndicador", [this.session.usuario['idCliente']])
      .pipe(take(1))
      .subscribe(response => {
        this.indicadores = response;
        this.cap = [];
        this.needleTail = [];

        for (let x = 0; x < response.length; x++) {
          this.cap.push({
            radius: 15,
            color: 'white',
            border: {
              color: response[x].queries[0].cor,
              width: 5
            }
          });

          this.needleTail.push({
            length: '22%',
            color: response[x].queries[0].cor,
          });
        }
      });
  }
}
