import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { GerarCotacaoRequestEntity } from '../entities/Requests/gerar-cotacao-request.entity';
import { GravarPedidoRequestEntity } from "../entities/Requests/gravar-pedido-request-entity";
import { GerarCotacaoResponseEntity } from '../entities/Responses/gerar-cotacao-response.entity';
import { AgrupadorPedidoEntity } from '../entities/agrupador-pedido.entity';
import { ModalEntity } from '../entities/modal.entity';
import { ServiceBase } from "../shared/service-base";

@Injectable({
  providedIn: 'root'
})
export class FinalizarPedidoService extends ServiceBase {

  constructor(
    private http: HttpClient
  ) { super(); }

  gravarPedido(request: GravarPedidoRequestEntity): Observable<AgrupadorPedidoEntity> {
    return this.http.post<AgrupadorPedidoEntity>(`${environment.baseUrl}api/pedido/GravarPedido`, request);
  }

  listarModais(): Observable<ModalEntity[]> {
    return this.http.get<ModalEntity[]>(`${environment.baseUrl}api/modal/listarModais`);
  }

  calcularCotacaoFrete(request: GerarCotacaoRequestEntity): Observable<GerarCotacaoResponseEntity> {
    return this.http.post<GerarCotacaoResponseEntity>(`${environment.baseUrl}api/cotacao/GerarCotacaoFrete`, request);
  }

  calcularCotacaoFreteEmpresas(empresas: string, request: GerarCotacaoRequestEntity): Observable<GerarCotacaoResponseEntity> {
    return this.http.post<GerarCotacaoResponseEntity>(`${environment.baseUrl}api/cotacao/GerarCotacaoFreteEmpresas?empresas=${empresas}`, request);
  }

  incluirPedido(request: GravarPedidoRequestEntity): Observable<AgrupadorPedidoEntity> {
    console.log(request);
    return this.http.post<AgrupadorPedidoEntity>(`${environment.baseUrl}api/v2/pedido`, request);
  }
}
