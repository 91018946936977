import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { InserirNotaFiscalEntradaRequestEntity } from '../../../entities/Requests/inserir-notafiscal-entrada-nf-request.entity';
import { AlertService } from '../../../shared/alert.service';
import { ComponentBase } from '../../../shared/component-base';
import { ProdutoService } from '../../produto.service';

@Component({
  selector: 'app-entrada-produto-dados-nf',
  templateUrl: './entrada-produto-dados-nf.component.html',
  styleUrls: ['./entrada-produto-dados-nf.component.css']
})
export class EntradaProdutoDadosNfComponent extends ComponentBase implements OnInit {

  notaFiscal: InserirNotaFiscalEntradaRequestEntity = new InserirNotaFiscalEntradaRequestEntity();
  codigosFiscais$: Observable<any[]>;

  constructor(
    private produtoService: ProdutoService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.codigosFiscais$ = this.loading2<any>(() => this.produtoService.listarCodigosFiscais());
  }

  validarNotaFiscal(): boolean {
    if (this.notaFiscal.numeroNotaFiscal || this.notaFiscal.serie || this.notaFiscal.dataNotaFiscal || this.notaFiscal.codigoFiscal || this.notaFiscal.valorNotaFiscal) {
      if (!this.notaFiscal.numeroNotaFiscal) {
        this.alert.warning("Preencha o campo Número da Nota Fiscal.");
        return false;
      }

      if (!this.notaFiscal.serie) {
        this.alert.warning("Preencha o campo Série.");
        return false;
      }

      if (!this.notaFiscal.dataNotaFiscal) {
        this.alert.warning("Preencha o campo Data da Nota Fiscal.");
        return false;
      }

      if (!this.notaFiscal.codigoFiscal) {
        this.alert.warning("Preencha o campo Código Fiscal.");
        return false;
      }

      if (!this.notaFiscal.valorNotaFiscal) {
        this.alert.warning("Preencha o campo Valor da Nota Fiscal.");
        return false;
      }
    }

    return true;
  }
}
