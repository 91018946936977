import { take } from 'rxjs/operators';
import { ProdutoEntity } from './../../entities/produto.entity';
import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { ProdutoService } from '../produto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultarSaldoProdutoResponseEntity } from '../entities/response/consultar-saldo-produto-response.entity';
import { MovimentoProdutoResponseEntity } from '../entities/response/movimento-produto-response.entity';
import { FichaProdutoEntity } from '../../entities/ficha-produto.entity';

@Component({
  selector: 'app-produto-movimento',
  templateUrl: './produto-movimento.component.html',
  styleUrls: ['./produto-movimento.component.css']
})
export class ProdutoMovimentoComponent extends ComponentBase implements OnInit {
  produto:FichaProdutoEntity;
  saldo: ConsultarSaldoProdutoResponseEntity;
  movimentos:MovimentoProdutoResponseEntity[] = [];

  constructor(
    private produtoService: ProdutoService,
    private activatedRoute: ActivatedRoute,
    protected router : Router
  ) {
    super();
  }

  ngOnInit() {
    const codigoCliente = Number(this.activatedRoute.snapshot.paramMap.get('cliente'));
    const codigoProduto = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    this.loading2<FichaProdutoEntity>(()=> this.produtoService.consultarFichaProduto(codigoCliente, codigoProduto))
    .pipe(take(1))
    .subscribe(resp => {
      this.produto = resp;
    });

    this.loading2<ConsultarSaldoProdutoResponseEntity>(()=> this.produtoService.consultarSaldoProduto(codigoProduto))
    .pipe(take(1))
    .subscribe(resp => {
      this.saldo = resp;
    });

    this.loading2<MovimentoProdutoResponseEntity[]>(()=> this.produtoService.consultarMovimentoProduto(codigoProduto))
    .pipe(take(1))
    .subscribe(resp => {
      this.movimentos = resp;
    });
  }
}
