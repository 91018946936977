import { RelatorioFiltroEntity } from "./relatorio-filtro.entity";

export class RelatorioEntity {
  codigoRelatorio: number;
  nomeRelatorio: string;
  codigoCategoria: number;
  nomeCategoria: string;
  observacao: string;
  queryConsulta: string;
  usoInterno: boolean;
  filtros: RelatorioFiltroEntity[];
  idRecurso:number;
}
