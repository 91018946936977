import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatIconModule, MatInputModule, MatListModule, MatNativeDateModule, MatSidenavModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DemoMaterialModule } from '../demo-material-module';
import { FullComponent } from '../layouts/full/full.component';
import { AppHeaderComponent } from '../layouts/full/header/header.component';
import { AppSidebarComponent } from '../layouts/full/sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { SpinnerComponent } from '../shared/spinner.component';
import { InputComponent } from './input/input.component';
import { LayoutComponent } from './layout.component';
import { BreadCrumbComponent } from './masterpage/bread-crumb/bread-crumb.component';
import { MasterpageComponent } from './masterpage/masterpage.component';
import { LogoComponent } from './masterpage/topbar/logo/logo.component';
import { CarrinhoComponent } from './masterpage/topbar/nav-bar/carrinho/carrinho.component';
import { InboxComponent } from './masterpage/topbar/nav-bar/inbox/inbox.component';
import { NavBarComponent } from './masterpage/topbar/nav-bar/nav-bar.component';
import { NotificationsComponent } from './masterpage/topbar/nav-bar/notifications/notifications.component';
import { UserControlComponent } from './masterpage/topbar/nav-bar/user-control/user-control.component';
import { UserProfileComponent } from './masterpage/topbar/nav-bar/user-profile/user-profile.component';
import { MenuCollapseComponent } from './masterpage/topbar/nav-items/menu-collapse/menu-collapse.component';
import { MessagesComponent } from './masterpage/topbar/nav-items/messages/messages.component';
import { NavItemsComponent } from './masterpage/topbar/nav-items/nav-items.component';
import { SearchComponent } from './masterpage/topbar/nav-items/search/search.component';
import { SelectComponent } from './select/select.component';
import { SwitchComponent } from './switch/switch.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    RouterModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatSlideToggleModule,
    PerfectScrollbarModule,
    SharedModule,
    DemoMaterialModule,
    NgxMaskModule.forRoot()

  ],
  declarations: [
    LayoutComponent,
    MasterpageComponent,
    InputComponent,
    SelectComponent,
    AppSidebarComponent,
    LogoComponent,
    NavItemsComponent,
    MenuCollapseComponent,
    SearchComponent,
    MessagesComponent,
    NavBarComponent,
    CarrinhoComponent,
    NotificationsComponent,
    UserControlComponent,
    UserProfileComponent,
    InboxComponent,
    BreadCrumbComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    SwitchComponent
  ],
  exports: [
    MasterpageComponent,
    InputComponent,
    SelectComponent,
    SpinnerComponent,
    SwitchComponent
  ]
})
export class LayoutModule { }
