import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceBase } from '../../shared/service-base';
import { LocalizacaoEntity } from '../entities/localizacao.entity';

@Injectable({
  providedIn: 'root'
})
export class LocalizacaoService extends ServiceBase {

  constructor(
    private http: HttpClient
  ) { super(); }

  inserirLocalizacao(request: LocalizacaoEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/v2/Localizacao`, request);
  }

  alterarLocalizacao(request: LocalizacaoEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/v2/Localizacao`, request);
  }

  listarLocalizacao(): Observable<LocalizacaoEntity[]> {
    return this.http.get<LocalizacaoEntity[]>(`${environment.baseUrl}api/v2/Localizacao/`);
  }

  consultarLocalizacaoPorTipo(tipo: number): Observable<LocalizacaoEntity[]> {
    return this.http.get<LocalizacaoEntity[]>(`${environment.baseUrl}api/v2/Localizacao/tipo/${tipo}`);
  }

  consultarLocalizacao(idLocalizacao: number): Observable<LocalizacaoEntity[]> {
    return this.http.get<LocalizacaoEntity[]>(`${environment.baseUrl}api/v2/Localizacao/${idLocalizacao}`);
  }

  excluirLocalizacao(idLocalizacao: number): Observable<LocalizacaoEntity[]> {
    return this.http.delete<LocalizacaoEntity[]>(`${environment.baseUrl}api/v2/Localizacao/${idLocalizacao}`);
  }
}
