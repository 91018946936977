import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { RelatorioEnvioResponseEntity } from '../../entities/Responses/relatorio-envio-response.entity';

@Component({
  selector: 'app-envios',
  templateUrl: './envios.component.html',
  styleUrls: ['./envios.component.css']
})
export class EnviosComponent extends ComponentBase implements OnInit{

  resultado:RelatorioEnvioResponseEntity[] = [];
  
  constructor() { 
    super();
  }

  ngOnInit(){
    this.resultado = [];
    super.ngOnInit();
  }

  onFilter(resultado:RelatorioEnvioResponseEntity[]){
  
    if (resultado.length == 0){
      this.resultado = [];
      this.clearDataTable("dtEnvios");
      this.alert.warning("Nenhum resultado foi obtido com base nos filtros informados.");
      return;
    }
    
    this.resultado = resultado;
    this.refreshDataTable("dtEnvios");
  }

}
