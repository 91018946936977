import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ServiceBase } from "../shared/service-base";
import { CategoriaProdutoEntity } from './entities/categoria-produto.entity';


@Injectable({
  providedIn: 'root'
})
export class CadastroService extends ServiceBase {

  constructor(
    private http: HttpClient
  ) { super(); }

  inserirCategoria(request: CategoriaProdutoEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/Produto/InserirCategoriaProduto`, request);
  }

  alterarCategoria(request: CategoriaProdutoEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/Produto/AtualizarCategoriaProduto`, request);
  }

  listarCategoriasProdutosMarca(idCliente: number, tipo: string): Observable<CategoriaProdutoEntity[]> {
    return this.http.get<CategoriaProdutoEntity[]>(`${environment.baseUrl}api/Produto/ListarCategoriasProdutosMarca/${idCliente}/${tipo}`);
  }

  consultarCategorias(tipo: string, idCliente?: number): Observable<CategoriaProdutoEntity[]> {
    return this.http.get<CategoriaProdutoEntity[]>(`${environment.baseUrl}api/Produto/ConsultarCategoriasProdutos/${tipo}/${idCliente ? idCliente : ''}`);
  }



}
