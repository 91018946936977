export class RelatorioRecebimentosRequestEntity {
  cliente: number;
  codigo: string;
  nf: string;
  NfNumber: number;
  descricao: string;
  fornecedor: string;
  cpfCnpj: string;
  nfFornecedor: string;
  dtIni: Date; 
  dtFim: Date;
}
