import { PipeTransform, Pipe } from "@angular/core";
import { CommonService } from "../shared/common.service";

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {

  constructor(
    private common: CommonService
  ) {}

  transform(value: any, args?: any): any {
    return this.common.formatarCep(value);
  }
}
