import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DestinatarioService } from '../destinatario/destinatario.service';
import { CidadeEntity } from '../entities/CidadeEntity';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';


const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CidadeComponent),
  multi: true
};

@Component({
  selector: 'app-cidade',
  templateUrl: './cidade.component.html',
  styleUrls: ['./cidade.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CidadeComponent extends ComponentBase implements OnInit {

  cidades: CidadeEntity[];
  loadingCidades: boolean = true;

  constructor(
    private destinatario: DestinatarioService,
    private session: SessionService
  ) { super(); }

  ngOnInit() {
    let empresas = null;
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente;
    else
      empresas = this.session.getIdsEmpresa();

    this.loadingGestores<any[]>(this.destinatario, "listarCidades", [empresas])
      .pipe(take(1))
      .subscribe(response => {
        this.cidades = [];

        for (var x = 0; x < response.length; x++)
          for (var y = 0; y < response[x].cidades.length; y++)
            this.cidades.push(response[x].cidades[y]);

        this.loadingCidades = false;
      });
  }

  changeUF(idEstado: number) {
    this.loadingCidades = true;

    let empresas = null;
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente;
    else
      empresas = 0;

    if (!idEstado) {
      this.call<any[]>(this.destinatario, "listarCidades", [empresas])
        .pipe(take(1))
        .subscribe(response => {
          this.cidades = response;
          this.loadingCidades = false;
        });
    } else {
      this.call<CidadeEntity[]>(this.destinatario, "consultarCidades", [empresas, idEstado])
        .pipe(take(1))
        .subscribe(response => {
          this.cidades = response;
          this.loadingCidades = false;
        });
    }
  }

  //The internal data model
  private innerSelectValue: any = '';

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  //get accessor
  get selectValue(): any {
    return this.innerSelectValue;
  }

  //set accessor including call the onchange callback
  set selectValue(value: any) {
    if (value !== this.innerSelectValue) {
      this.innerSelectValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerSelectValue)
      this.innerSelectValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
