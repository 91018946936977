import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-masterpage',
  templateUrl: './masterpage.component.html',
  styleUrls: ['./masterpage.component.css']
})
export class MasterpageComponent extends ComponentBase implements OnInit {
  logoPath: string;
  usuarioMaster: boolean;
  fazPedidoWeb: boolean;
  aprovaPedido: boolean;
  nomeUsuario: string;
  msgCliente: string;

  constructor(
    private session: SessionService,
    protected alert: AlertService,
    protected router?: Router,
  ) { super(); }

  ngOnInit() {
     //(<any>$(".fix-header .topbar")).stick_in_parent({});
    this.logoPath = this.session.logoClientes + this.session.usuario['nomeCliente'] + ".png";
    this.nomeUsuario = this.session.usuario['nomePessoa'];
    if (this.session.empresa && this.session.empresa.mensagemCliente)
      this.msgCliente = this.session.empresa.mensagemCliente;
  }

  logoff() {
    this.alert.confirm("Tem certeza que deseja sair do aplicativo?")
      .then(res => {
        if (res) {
          this.session.clear();
          this.router.navigate(['/']);
        }
      });    
  }

  resetMsg() {
    let empresa = this.session.empresa;
    empresa.mensagemCliente = null;

    this.session.empresa = empresa;
  }
}
