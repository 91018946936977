import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AgrupadorPedidoEntity } from '../../entities/agrupador-pedido.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { ProdutoService } from '../../produto/produto.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent extends ComponentBase implements OnInit {

  observacao: string;
  @Input() agrupadorPedido: AgrupadorPedidoEntity;

  constructor(
    private produtoService: ProdutoService,
    private session: SessionService,
  ) { super(); }

  ngOnInit() {

  }
  getImagemProduto(produto: ProdutoEntity) {
    if (produto.imagemKit)
      return produto.imagemKit;

    if (this.session.empresa.produtoESL)
      return this.produtoService.getImagemProduto(produto.codigoProduto);
  }
}
