import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ConsultarDadosDashboardRequestEntity } from '../../entities/Requests/consultar-dados-dashboard.request.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-pedidos-estado',
  templateUrl: './pedidos-estado.component.html',
  styleUrls: ['./pedidos-estado.component.css']
})
export class PedidosEstadoComponent extends ComponentBase implements OnInit {

  data = [];

  constructor(
    private session: SessionService,
    private dashboardService: DashboardService
  ) { super(); }

  ngOnInit() {
    let entity = new ConsultarDadosDashboardRequestEntity();
    const currentDate = new Date();
    entity.clientes = this.session.getIdsEmpresa();
    entity.divisoes = this.session.getIdDivisoes();
    entity.dataInicial = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
    entity.dataFinal = currentDate;

    this.filtrarDados(entity);
  }

  filtrarDados(entity:ConsultarDadosDashboardRequestEntity){
    this.loading2<any[]>(() => this.dashboardService.obterPedidosEstado(entity))
    .pipe(take(1))
    .subscribe(resp => {
      this.data = resp;
    });
  }
  // custom code end
  public palette = ["#E94649", "#F6B53F", "#6FAAB0"];
  public center: Object = { x: '50%', y: '50%' };
  public startAngle: number = 0;
  public endAngle: number = 360;
  public explode: boolean = true;
  public enableAnimation: boolean = true;
  public tooltip: Object = { enable: true, format: '${point.x} : <b>${point.y} Pedidos</b>' };
  public title: string = 'Pedidos x Estado';

  //Initializing Legend
  public legendSettings: Object = {
    visible: true,
  };
  //Initializing Datalabel
  public dataLabel: Object = {
    visible: true,
    position: 'Inside', name: 'text',
    font: {
      fontWeight: '600'
    }
  };
}
