import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { DestinatarioService } from '../../destinatario/destinatario.service';
import { GerarCotacaoRequestEntity } from '../../entities/Requests/gerar-cotacao-request.entity';
import { ProdutoCotacaoFreteRequestEntity } from '../../entities/Requests/produto-cotacao-frete-request.entity';
import { GerarCotacaoResponseEntity } from '../../entities/Responses/gerar-cotacao-response.entity';
import { EmbalagemEntity } from '../../entities/embalagem.entity';
import { ModalEntity } from '../../entities/modal.entity';
import { PessoaEntity } from '../../entities/pessoa.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { CommonService } from '../../shared/common.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { FinalizarPedidoService } from '../finalizar-pedido.service.';

@Component({
  selector: 'app-lista-destinatario',
  templateUrl: './lista-destinatario.component.html',
  styleUrls: ['./lista-destinatario.component.css']
})
export class ListaDestinatarioComponent extends ComponentBase implements OnInit {
  pessoas: PessoaEntity[] = [];
  modal: NgbModalRef;
  modais: ModalEntity[] = [];
  pessoaFrete: PessoaEntity = new PessoaEntity();
  exibirDetalheFrete: boolean = false;
  funcionarioImperium: boolean = false;

  @ViewChild("modalDestinatarios", { static: true }) modalDestinatario: any;

  @Input() showRemove: boolean = false;
  @Input() showAdd: boolean = true;

  constructor(
    protected modalService: NgbModal,
    private destintarioService: DestinatarioService,
    private finalizarPedido: FinalizarPedidoService,
    private toastr: ToastrService,
    private session: SessionService,
    private carrinho: CarrinhoService,
    private common: CommonService

  ) { super(); }

  ngOnInit() {
    this.exibirDetalheFrete = this.session.usuario.funcionario;
    this.pessoas = this.destintarioService.destinatarios;
    this.funcionarioImperium = this.session.usuario.funcionario;

    this.loadingGestores<ModalEntity[]>(this.finalizarPedido, "listarModais", [])
      .pipe(take(1))
      .subscribe((resp) => {
        this.modais = resp;
      });
  }

  abrirListaDestinatarios() {

    // if (!this.pessoas || this.pessoas.length === 0)
    //   this.modalAddDestinatario();
  }

  excluirPessoa(index: number) {
    let pessoa = this.pessoas[index];
    this.destintarioService.removeDestinatario(pessoa);
    this.pessoas = this.destintarioService.destinatarios;
    this.toastr.success('Destinatário removido do pedido.', 'Destinatário removido', {
      progressBar: true,
      positionClass: 'toast-top-left'
    });
  }

  modalAddDestinatario() {
    this.modal = this.modalService.open(this.modalDestinatario, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
    this.modal.result.then((result) => {
      this.pessoas = this.destintarioService.destinatarios;
    }, (reason) => {
      this.pessoas = this.destintarioService.destinatarios;
    });
  }

  getPessoas(): PessoaEntity[] {
    return this.destintarioService.destinatarios;
  }

  changeModal(pessoa: PessoaEntity) {
    if (!pessoa || !pessoa.codigoModal) {
      if (pessoa)
        pessoa.cotacaoFrete = null;

      return;
    }

    let request = new GerarCotacaoRequestEntity();
    request.CidadeDestino = pessoa.idCidade;
    request.CodigoDestinatario = pessoa.codigoDestinatarioWeb;
    request.CodigoModal = pessoa.codigoModal;
    request.Produtos = this.obterProdutos();
    request.CepDestino = pessoa.cep;

    //Verifica se a empresa é Brasilit e faz o calculo para Brasilit e Brasilit2
    if (this.session.ajusteBrasilit) {
      this.loadingGestores<GerarCotacaoResponseEntity>(this.finalizarPedido, "calcularCotacaoFreteEmpresas", [this.carrinho.obterIdsEmpresasProdutos(), request])
        .pipe(take(1))
        .subscribe(resp => {
          pessoa.cotacaoFrete = resp;
        });
    } else if (this.session.empresa && !this.carrinho.possuiProdutosMultiplasEmpresa()) { // Se houver empresa selecionada, calcula o frete sobre a empresa
      request.IdCliente = this.session.empresa.idCliente;
      this.loadingGestores<GerarCotacaoResponseEntity>(this.finalizarPedido, "calcularCotacaoFrete", [request])
        .pipe(take(1))
        .subscribe(resp => {
          pessoa.cotacaoFrete = resp;
        });
    } else { //Senao calcula o frete enviando todas as empresas selecionadas
      this.loadingGestores<GerarCotacaoResponseEntity>(this.finalizarPedido, "calcularCotacaoFreteEmpresas", [this.carrinho.obterIdsEmpresasProdutos(), request])
        .pipe(take(1))
        .subscribe(resp => {
          pessoa.cotacaoFrete = resp;
        });
    }
  }

  obterProdutos(): ProdutoCotacaoFreteRequestEntity[] {
    return this.carrinho.itens.map<ProdutoCotacaoFreteRequestEntity>((x) => {
      var obj = new ProdutoCotacaoFreteRequestEntity();
      obj.codigoProduto = x.produto.codigoProduto;
      obj.quantidade = x.quantidade;
      obj.idCliente = x.produto.idCliente;

      return obj;
    });
  }

  possuiKit(): boolean {
    for (var x = 0; x < this.carrinho.itens.length; x++) {
      if (this.carrinho.itens[x].isKit)
        return true;
    }

    return false;
  }

  openModalFrete(modalFrete: any, pessoa: PessoaEntity) {
    this.pessoaFrete = pessoa;
    this.modal = this.modalService.open(modalFrete, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  obterEmbalagens(embalagens: EmbalagemEntity[]): string {
    let result = "";
    for (var x = 0; x < embalagens.length; x++) {
      if (this.pessoaFrete.cotacaoFrete.modalFrete === "C")
        result += embalagens[x].descricao + "(" + embalagens[x].pesoMaximoCorreio.toFixed(2) + "Kg)\n";
      else
        result += embalagens[x].descricao + "(" + embalagens[x].pesoMaximo.toFixed(2) + "Kg)\n";
    }

    return result;
  }

  addRemoveItem() {
    this.modal.close();
  }
}
