import { Injectable } from '@angular/core';
import { Jsonp } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private jsonp: Jsonp,
  ) { }

  removerCaracteresEspeciais(valor: string): string {
    if (!valor)
      return valor;

    return valor.replace(/[^\w\s]/gi, '').replace(" ", "");
  }

  formatarCpfCnpj(cpfCnpj: string) {
    let tpDoc = this.tipoDocumento(cpfCnpj);
    if (tpDoc == 'CPF')
      return cpfCnpj.substr(0, 3) + "." + cpfCnpj.substr(3, 3) + "." + cpfCnpj.substr(6, 3) + "-" + cpfCnpj.substr(9, 2);
    else
      return cpfCnpj.substr(0, 2) + "." + cpfCnpj.substr(2, 3) + "." + cpfCnpj.substr(5, 3) + "/" + cpfCnpj.substr(8, 4) + "-" + cpfCnpj.substr(12, 2);
  }

  formatarCep(cep: string) {
    cep = this.removerCaracteresEspeciais(cep);
    return cep.substr(0, 5) + "-" + cep.substr(5, 3);
  }

  formatarTelefoneComDDD(ddd: string, telefone: string): string {
    if (!ddd || !telefone)
      return "";

    telefone = this.removerCaracteresEspeciais(telefone);
    ddd = this.removerCaracteresEspeciais(ddd);

    if (telefone.substring(0, 1) === "9")
      return `(${ddd}) ${telefone.substring(0, 5)}-${telefone.substring(5)}`;
    else
      return `(${ddd}) ${telefone.substring(0, 4)}-${telefone.substring(4)}`;
  }

  formatarTelefone(telefone: string): string {
    telefone = this.removerCaracteresEspeciais(telefone);
    return this.formatarTelefoneComDDD(telefone.substring(0, 2), telefone.substring(2));
  }

  obterTelefoneComDDD(telefone: string) {
    telefone = this.removerCaracteresEspeciais(telefone);
    return {
      ddd: telefone.substring(0, 2),
      numero: telefone.substring(2)
    };
  }

  formatDecimal(value: string): string {
    if (!value) return "0";
    value = (parseFloat(value) * 100).toString();
    value.toString().replace(/\D/gi, '');
    let replace = "([0-9]{2})$";
    let re = new RegExp(replace, "g");

    var newValue: string[] = value.toString().replace(re, ',$1').split(',');
    newValue[0] = newValue[0].split(/(?=(?:...)*$)/).join('.');
    return newValue.join(',');
  }

  formatInteger(value: string): string {
    if (!value) return "0";
    value = (parseFloat(value) * 100).toString();
    value.toString().replace(/\D/gi, '');
    let replace = "([0-9]{2})$";
    let re = new RegExp(replace, "g");

    var newValue: string[] = value.toString().replace(re, ',$1').split(',');
    newValue[0] = newValue[0].split(/(?=(?:...)*$)/).join('.');
    return newValue[0];
  }

  tipoDocumento(cpfCnpj: string): string {
    cpfCnpj = this.removerCaracteresEspeciais(cpfCnpj);
    return cpfCnpj.length > 11 ? "CNPJ" : "CPF";
  }

  consultarIp(): Observable<any> {
    return this.jsonp.get('//api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK');
  }

  downloadFile(blob: Blob, fileName: string): void {
    const file = new Blob([blob], {
      type: blob.type
    });

    const fileURL = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));

    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
      link.remove();
    }, 100);
  }

  entityToQuery(entity: any): string {
    return '?' +
      Object.keys(entity)
        .map(key => `${key}=${encodeURIComponent(entity[key])}`)
        .join('&')
  }
}
