import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicadorComponent } from './indicador.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '../layout/layout.module';
import { CircularGaugeModule, GaugeTooltipService } from '@syncfusion/ej2-ng-circulargauge';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    //CircularGaugeModule,
    TooltipModule
  ],
  declarations: [IndicadorComponent],
  exports: [IndicadorComponent],
  providers:[GaugeTooltipService]
})
export class IndicadorModule { }
