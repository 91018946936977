import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AlertService } from "../shared/alert.service";
import { AuthorizationService } from "../shared/authorization.service";
import { SessionService } from "../shared/session.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  ignoreUrls = ['viacep.com.br', 'api.ipify.org'];

  constructor(
    private session: SessionService,
    private alert: AlertService,
    private authorization: AuthorizationService
  ) { }

  private handleError(req: HttpRequest<any>, error: HttpErrorResponse) {

    if (error.status) {
      if (error.status == 400)
        if (typeof error.error == "string")
          this.alert.warning(error.error);
        else
          this.alert.warning(JSON.stringify(error.error));
      else
        this.alert.error(`Url: ${req.url}`, error.status);
    }
    else
      this.alert.error(`Url: ${req.url}`);

    return throwError(error);
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {

    //let authenticate = error.headers.get('www-authenticate');
    //if (authenticate && authenticate.indexOf('Bearer') >= -1 && authenticate.indexOf('expired') >= -1) {
      return this.authorization.renovarToken()
        .pipe(
          switchMap(() => {
            req = this.addAuthHeader(req);
            return next.handle(req);
          })
        );
    //}

    return this.handleError(req, error);
  }

  private addAuthHeader(request: HttpRequest<any>) {
    for (var x = 0; x < this.ignoreUrls.length; x++) {
      if (request.url.indexOf(this.ignoreUrls[x]) > -1)
        return request;
    }

    if (this.authorization.token) {
      return request.clone({
        setHeaders: {
          "Authorization": `Bearer ${this.authorization.token}`
        }
      });
    }

    return request;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthHeader(req);

    return next.handle(req).pipe(
      catchError(
        error => {
          if (error.status && error.status == 401) {
            return this.handle401Error(req, next, error);
          }

          return this.handleError(req, error);
        })
    );
  }
}
