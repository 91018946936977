import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoClienteEntity } from '../entities/produto-cliente.entity';
import { ProdutoService } from '../produto.service';


@Component({
  selector: 'app-cadastro-produto',
  templateUrl: './cadastro-produto.component.html',
  styleUrls: ['./cadastro-produto.component.css']
})
export class CadastroProdutoComponent extends ComponentBase implements OnInit {
  produto: ProdutoClienteEntity = new ProdutoClienteEntity()
  produtos: ProdutoClienteEntity[] = [];
  showIncluirAlterar: boolean = false;
  modal: NgbModalRef;
  idCliente: number;
  edicao: boolean;
  imagem: SafeResourceUrl;

  constructor(
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected modalService: NgbModal,
    protected router: Router,
    private session: SessionService,
    private sanitizer: DomSanitizer
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();

    if (!this.session.empresa) {
      this.alert.warning("Para consultar as categorias de produtos é necessário ter selecionado uma empresa.")
        .then(() => {
          this.session.urlReturn = window.location.href;
          this.router.navigate(['/empresa/selecionar']);
        });
      return;
    }

    if (this.session.empresa)
      this.idCliente = this.session.empresa.idCliente;

    this.consultarProdutos();
  }

  consultarProdutos() {
    this.loading2<ProdutoClienteEntity[]>(() => this.produtoService.consultarProdutosCliente(this.idCliente))
      .pipe(take(1))
      .subscribe(resp => {

        this.produtos = resp;
        if (resp.length > 0)
          this.refreshDataTable('dtProdutos');
        else {
          this.clearDataTable('dtProdutos');
        }
      });
  }

  consultarImagemProduto(produto: ProdutoClienteEntity): void {
    this.loading2<string>(() => this.produtoService.consultarImagemProduto(produto.idProduto))
      .pipe(take(1))
      .subscribe(resp => {
        const sanitizer = this.sanitizer;
        if (resp) {
          produto.imagem = sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp);
        }
      });
  }

  inserirProduto(): void {
    this.produto = new ProdutoClienteEntity();
    this.produto.idCliente = this.idCliente;
    this.produto.ativo = true;
    this.edicao = true;
  }

  alterarProduto(produto: ProdutoClienteEntity): void {
    this.produto = produto;
    this.edicao = true;
  }

  salvarCancelar(): void {
    this.edicao = false;
    this.consultarProdutos();
  }
}
