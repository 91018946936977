import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { CidadeModule } from '../cidade/cidade.module';
import { DivisaoModule } from '../divisao/divisao.module';
import { LayoutModule } from '../layout/layout.module';
import { PipesModule } from '../pipes/pipes.module';
import { ProdutoModule } from '../produto/produto.module';
import { UfModule } from '../uf/uf.module';
import { DeclaracaoTransporteProdutoComponent } from './declaracao-produto/declaracao-transporte-produto.component';
import { DetalhePedidoComponent } from './detalhe-pedido/detalhe-pedido.component';
import { FiltroPedidoComponent } from './filtro-pedido/filtro-pedido.component';
import { ImportarPlanilhaComponent } from './importar-planilha/importar-planilha.component';
import { PedidosComponent } from './pedidos.component';
import { VisualizarDeclaracaoProdutoComponent } from './visualizar-declaracao-produto/visualizar-declaracao-produto.component';
import { PedidoImpressaoComponent } from './pedido-impressao/pedido-impressao.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DropzoneModule,
    LayoutModule,
    DataTablesModule,
    DivisaoModule,
    UfModule,
    CidadeModule,
    ProdutoModule,
    PipesModule
  ],
  declarations: [PedidosComponent,
    FiltroPedidoComponent,
    DetalhePedidoComponent,
    ImportarPlanilhaComponent,
    DeclaracaoTransporteProdutoComponent,
    VisualizarDeclaracaoProdutoComponent,
    PedidoImpressaoComponent
  ],
  exports: [
    PedidosComponent,
    FiltroPedidoComponent,
    DetalhePedidoComponent,
    ImportarPlanilhaComponent
  ]
})
export class PedidosModule { }
