import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'origemMovimentoEstoque'
})
export class OrigemMovimentoEstoque implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 'PED')
      return 'Pedido';
    else if (value === 'NFE')
      return 'Nota Fiscal de Entrada';
    else if (value === 'NFS')
      return 'Nota Fiscal de Saída';
    else if (value === 'OS')
      return 'Ordem de Saída';

    return null;
  }
}
