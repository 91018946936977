import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateCustomParserFormatter } from '../../shared/ngb-date-custom-parser-formatter';
import { MaskService } from './mask.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  classe: string;
  @Input() title: string;
  @Input() labelClass: string;
  @Input() inputGroupClass: string;
  @Input() class: string;
  @Input() maxlength: number;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() field: NgModel;
  @Input() validationMessage: string;
  @Input() inputGroupText: string;
  @Input() type: string = 'text';
  @Input() mask: string;
  @Input() reverseLabel: boolean;
  @Input() modalAddName: string;
  @Input() material: boolean = true;
  @Input() modalFilterName: string;
  @Input() datepicker: boolean;
  @Input() showRequiredIndicator: boolean;
  @Input() formControl: FormControl;
  @Input() name: string;
  @Input() maxValue: number;

  @Output() lostFocus: EventEmitter<any> = new EventEmitter();
  @Output() cleanClick: EventEmitter<any> = new EventEmitter();

  @ViewChild("appInputRef", { static: false }) inputRef: any;

  constructor(
    private maskService: MaskService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
    this.classe = "";
    if (!this.disabled && this.required && this.validationMessage && !this.field.valid && this.field.touched) {
      if (this.material) {
        this.class = "form-control input-error form-control2 input-error2";
      } else {
        this.class = 'form-control input-error input-error3';
      }
    } else {
      if (this.material) {
        this.class = 'form-control form-control2';
      } else {
        this.class = 'form-control';
      }
    }


    this.datepicker = this.datepicker != undefined;
    if (this.datepicker)
      this.mask = '00/00/0000';
  }
  data: Date = new Date();
  ngAfterViewInit() {
    let inputRef = this.inputRef;
    // if (this.datepicker)
    // inputRef = (<any>this.inputRef)._elRef;
    this.maskService.init(this.mask, inputRef);
  }

  //The internal data model
  private innerValue: any = '';

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  // Evento acionado quando ocorre modificação no DOM.
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = this.maskService.transform(this.mask, v, this.innerValue, this.inputRef);
      this.onChangeCallback(this.maskService.parser(this.mask, this.innerValue, this.inputRef));
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
    this.lostFocus.emit();
  }

  onCleanClick() {
    this.cleanClick.emit();
  }

  //From ControlValueAccessor interface
  // Evento acionado quando ocorre modificação na model
  writeValue(value: any) {
    if (value !== this.innerValue) {
      value = this.maskService.formatter(this.mask, value, this.inputRef);
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  // Evento acionado quando ocorre modificação no DOM.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validateMaxValue() {
    if (this.maxValue && this.value > this.maxValue) {
      this.value = this.maxValue;
    }
  }
}
