import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { ConsultarDadosDashboardRequestEntity } from '../entities/Requests/consultar-dados-dashboard.request.entity';
import { ObterTotalizadoresResponseEntity } from '../entities/Responses/obter-totalizadores.response.entity';
import { ServiceBase } from "../shared/service-base";

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends ServiceBase {

  constructor(
    private http: HttpClient
  ) { super(); }

  obterTotalizadores(entity: ConsultarDadosDashboardRequestEntity): Observable<ObterTotalizadoresResponseEntity> {
    return this.http.post<ObterTotalizadoresResponseEntity>(`${environment.baseUrl}api/dashboard/ObterTotalizadores`, entity);
  }

  obterPedidosMarca(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterPedidosMarca`, entity);
  }

  obterPedidosMarcaPercentual(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterPedidosMarcaPercentual`, entity);
  }

  obterPedidosEstado(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterPedidosEstado`, entity);
  }

  obterTop5PedidosEstado(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterTop5PedidosEstado`, entity);
  }

  obterPedidosCoordenador(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterPedidosCoordenador`, entity);
  }

  obterVendedoresPorRegional(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterVendedoresPorRegional`, entity);
  }

  obterPedidosMarcaCoordenador(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterPedidosMarcaCoordenador`, entity);
  }

  obterKmRodadosMes(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterKmRodadosMes`, entity);
  }

  obterKmRodadosFreteCompartilhado(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterKmRodadosFreteCompartilhado`, entity);
  }

  obterCoordenadorGerente(entity: ConsultarDadosDashboardRequestEntity): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}api/dashboard/ObterCoordenadorGerente`, entity);
  }
}
