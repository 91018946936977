import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { CategoriaEntity } from '../../entities/catetoria.entity';
import { ServiceBase } from '../../shared/service-base';

@Injectable({
  providedIn: 'root'
})
export class RelatorioCategoriaService extends ServiceBase {

  constructor(
    private http: HttpClient
  )
  { super(); }

  inserirCategoria(request: CategoriaEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/categoriaRelatorio/InserirCategoria`, request);
  }

  alterarCategoria(request: CategoriaEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/categoriaRelatorio/alterarCategoria`, request);
  }

  excluirCategoria(idCategoria: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/categoriaRelatorio/excluirCategoria/${idCategoria}`);
  }

  consultarCategoria(idCategoria: number): Observable<CategoriaEntity> {
    return this.http.get<CategoriaEntity>(`${environment.baseUrl}api/categoriaRelatorio/consultarCategoria/${idCategoria}`);
  }

  listarCategorias(): Observable<CategoriaEntity[]> {
    return this.http.get<CategoriaEntity[]>(`${environment.baseUrl}api/categoriaRelatorio/ListarCategoria/`);
  }
}
