import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../shared/component-base';

@Component({
  selector: 'app-consulta-pedido',
  templateUrl: './consulta-pedido.component.html',
  styleUrls: ['./consulta-pedido.component.css']
})
export class ConsultaPedidoComponent extends ComponentBase implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
