import { Injectable } from '@angular/core';
import { ServiceBase } from "../shared/service-base";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LoginEntity } from "../entities/login.entity";
import { AtualizarDadosPessoaisRequestEntity } from "../entities/Requests/atualizar-dados-pessoais";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class AnonymousService extends ServiceBase {
  constructor(
    private http: HttpClient
  ) { super(); }


  alterarSenha(id: string, senha: string, confSenha: string): Observable<void> {
    const p:any = {};
    p.id = id;
    p.senha = senha;
    p.confSenha = confSenha;

    return this.http.post<void>(`${environment.baseUrl}api/autenticacao/AlterarSenhaSolicitacao/`, p);
  }

  solicitarRecuperacaoSenha(usuario: string): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/autenticacao/SolicitarRecuperacaoSenha?usuario=${usuario}`, null);
  }

  atualizarDadosPessoais(request: AtualizarDadosPessoaisRequestEntity) {
    return this.http.post<void>(`${environment.baseUrl}api/autenticacao/atualizarDadosPessoais`, request);
  }

  consultarDadosPessoais(idPessoa: number) {
    return this.http.get<AtualizarDadosPessoaisRequestEntity>(`${environment.baseUrl}api/autenticacao/ConsultarDadosPessoais/${idPessoa}`);
  }
}
