import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultaPedidoComponent } from './consulta-pedido.component';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { PedidosModule } from '../pedidos/pedidos.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    PedidosModule
  ],
  declarations: [ConsultaPedidoComponent],
  exports: [ConsultaPedidoComponent]
})
export class ConsultaPedidoModule { }
