import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ImportacaoPlanilhaEntity } from '../../entities/importacao-planilha.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { PedidoService } from '../pedidos.service';

@Component({
  selector: 'app-importar-planilha',
  templateUrl: './importar-planilha.component.html',
  styleUrls: ['./importar-planilha.component.css']
})
export class ImportarPlanilhaComponent extends ComponentBase implements OnInit {
  importacoes: any[] = [];
  urlDownload: string;
  funcionario: boolean = false;
  config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: 5000,
    errorReset: 30000,
    cancelReset: null,
    maxFilesize: 50,
    url: 'http://',
    acceptedFiles: '.xls, .xlsx',
    uploadMultiple: false,
    createImageThumbnails: true
  };

  uploadMsg = "";
  constructor(
    private session: SessionService,
    protected alert: AlertService,
    private importacaoPlanilha: PedidoService,
    protected router: Router
  ) { super(); }

  onChangeTipoPlanilha() {
    let usuario = this.session.usuario['idPessoa'];
    let cliente = this.session.usuario['idCliente'];
    let url = `${environment.baseUrl}api/ImportacaoPlanilha/InserirImportacao?idCliente=${cliente}&idUsuario=${usuario}`;
    this.config.url = url;
  }

  ngOnInit() {
    if (!this.session.empresa) {
      this.alert.warning('Para importar uma planilha é necessário ter escolhido uma empresa.')
        .then(() => {
          this.session.urlReturn = window.location.href;
          this.router.navigate(['/empresa/selecionar']);
        });
      return;
    }
    super.ngOnInit();
    this.onChangeTipoPlanilha();
    this.urlDownload = environment.baseUrl + "planilhas/";
    this.funcionario = this.session.usuario['funcionario']
    let usuario = this.session.usuario['idPessoa'];
    let cliente = this.session.usuario['idCliente'];


    this.loadingGestores<ImportacaoPlanilhaEntity[]>(this.importacaoPlanilha, "consultarImportacao", [cliente, usuario])
      .pipe(take(1))
      .subscribe(resp => {
        this.importacoes = resp;
        this.refreshDataTable('dtImportacoes');
      });

  }

  onUploadError(obj: any) {
    this.alert.warning("Ocorreu uma falha durante a importação da sua planilha. Tente novamente em alguns instantes.");
  }

  onUploadSuccess(obj: any) {
    const email = this.session.usuario['email'];
    this.alert.info(`Sua planilha foi importada com sucesso.`);
  }

  alterarStatus(status: string, importacao: ImportacaoPlanilhaEntity) {
    this.loadingGestores<any>(this.importacaoPlanilha, "alterarStatusImportacao", [importacao.codigoImportacao, status])
      .pipe(take(1))
      .subscribe(x => {
        importacao.status = status;
        this.alert.info(`Status alterado com sucesso.`);
      });
  }
}
