import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinatarioComponent } from './destinatario.component';
import { FiltroDestinatarioComponent } from './filtro-destinatario/filtro-destinatario.component';
import { TableDestinatarioComponent } from './table-destinatario/table-destinatario.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '../layout/layout.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    DataTablesModule,
    NgSelectModule,
    PipesModule
  ],
  declarations: [
    DestinatarioComponent,
    FiltroDestinatarioComponent,
    TableDestinatarioComponent
  ],
  exports: [
    DestinatarioComponent
  ]
})
export class DestinatarioModule { }
