import { Pipe, PipeTransform } from "@angular/core";
import { CommonService } from "../shared/common.service";

@Pipe({
  name: 'situacaoSolicitacao'
})
export class SituacaoSolicitacaoPipe implements PipeTransform {

  constructor(
    private common: CommonService
  ) { }

  transform(value: any, args?: any): any {
    switch (value) {
      case "AB":
        return "Em Aberto";
      case "AP":
        return "Aprovado";
      case "RP":
        return "Reprovado";
      case "FI":
        return "Finalizado";
      default:
        return "Em Aberto";
    }
  }
}
