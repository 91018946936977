import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AtualizarDadosPessoaisRequestEntity } from '../../entities/Requests/atualizar-dados-pessoais';
import { AlertService } from '../../shared/alert.service';
import { CepService } from '../../shared/cep.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { AnonymousService } from '../anonymous.services';

@Component({
  selector: 'app-alterar-dados-pessoais',
  templateUrl: './alterar-dados-pessoais.component.html',
  styleUrls: ['./alterar-dados-pessoais.component.scss']
})
export class AlterarDadosPessoaisComponent extends ComponentBase implements OnInit {

  form: FormGroup;
  maskTelefone: string = '(00)0000-0000';
  nomeUsuario: string;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private anonymousService: AnonymousService,
    private session: SessionService,
    protected alert: AlertService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit() {

    this.form = this.fb.group({
      //  nome: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      telefone: [null, Validators.compose([Validators.required])],
      cep: [null, Validators.compose([Validators.required])],
      endereco: [null, Validators.compose([Validators.required])],
      numero: [null, Validators.compose([Validators.required])],
      complemento: [null],
      bairro: [null, Validators.compose([Validators.required])],
      cidade: [null, Validators.compose([Validators.required])],
      uf: [null, Validators.compose([Validators.required])],
      ibge: [null]
    });

    this.loadingGestores<AtualizarDadosPessoaisRequestEntity>(this.anonymousService, "consultarDadosPessoais", [this.session.usuario.idPessoa])
      .pipe(take(1))
      .subscribe(resp => {
        if (!resp)
          return;
        this.form.controls['bairro'].setValue(resp.bairro);
        this.form.controls['cep'].setValue(resp.cep);
        this.form.controls['cidade'].setValue(resp.cidade);
        this.form.controls['ibge'].setValue(resp.codigoIbge);
        this.form.controls['complemento'].setValue(resp.complemento);
        this.form.controls['email'].setValue(resp.email ? resp.email.trim() : '');
        this.form.controls['endereco'].setValue(resp.endereco);
        this.nomeUsuario = resp.nomeCompleto;
        //this.form.controls['nome'].setValue(resp.nomeCompleto);
        this.form.controls['numero'].setValue(resp.numero);
        this.form.controls['telefone'].setValue(resp.telefone);
        this.form.controls['uf'].setValue(resp.uf);

      });
  }

  changeTelefone() {

    if (this.form.controls['telefone'].value[2] === '9')
      this.maskTelefone = '(00) 00000-0000'
    else
      this.maskTelefone = '(00) 0000-0000'

  }

  submit() {
    let request = new AtualizarDadosPessoaisRequestEntity();
    request.idPessoa = this.session.usuario.idPessoa;
    request.telefone = this.form.controls['telefone'].value;
    request.bairro = this.form.controls['bairro'].value;
    request.nomeCompleto = this.nomeUsuario;
    request.email = this.form.controls['email'].value;
    request.cep = this.form.controls['cep'].value;
    request.cidade = this.form.controls['cidade'].value;
    request.complemento = this.form.controls['complemento'].value;
    request.cidade = this.form.controls['cidade'].value;
    request.uf = this.form.controls['uf'].value;
    request.numero = this.form.controls['numero'].value;
    request.codigoIbge = this.form.controls['ibge'].value;
    request.endereco = this.form.controls['endereco'].value;

    this.loadingGestores(this.anonymousService, "atualizarDadosPessoais", [request])
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Dados atualizados com sucesso");
        this.router.navigate(['/produtos']);
      });
  }

  pesquisarCep() {
    this.loadingGestores(this.cepService, "consultarCep", [this.form.controls['cep'].value])
      .pipe(take(1))
      .subscribe(resp => {

        this.form.controls['endereco'].setValue(resp['logradouro']);
        this.form.controls['bairro'].setValue(resp['bairro']);
        this.form.controls['cidade'].setValue(resp['localidade']);
        this.form.controls['uf'].setValue(resp['uf']);
        this.form.controls['ibge'].setValue(resp['ibge']);
      });
  }
}
