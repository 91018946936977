import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ServiceBase } from './service-base';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends ServiceBase {
  constructor(
    private http: HttpClient,
    private session: SessionService
  ) {
    super();
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  autenticar(usuario: any): Observable<object> {
    return this.http.post<object>(`${environment.baseUrl}api/autenticacao/autenticar`, usuario)
      .pipe(
        tap(response => {
        localStorage.setItem('token', response['token']);
        localStorage.setItem('pathImages', response['pathImages']);
        localStorage.setItem('logoClientes', response['logoClientes']);
        localStorage.setItem('pathImagesPDF', response['pathImagesPDF']);
        localStorage.setItem('logoClientesPDF', response['logoClientesPDF']);
        localStorage.setItem('data', JSON.stringify(response['data']));
        })
      );
  }

  renovarToken(): Observable<object> {
    return this.autenticar(JSON.parse(localStorage.getItem('data')));
  }
}
