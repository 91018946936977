import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

function isNumber(value: any): boolean {
  return !isNaN(parseInt(value));
}

export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  constructor() { super(); }

  parse(value: string): NgbDateStruct {
    if (value && value.length === 10) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: parseInt(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: parseInt(dateParts[1]), month: parseInt(dateParts[0]), day: null };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { year: parseInt(dateParts[2]), month: parseInt(dateParts[1]), day: parseInt(dateParts[0]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date)
      return moment(new Date(date.year, date.month - 1, date.day)).format('DD/MM/YYYY');
    return null;
  }
}
