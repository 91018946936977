import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { AnonymousService } from '../anonymous.services';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent extends ComponentBase implements OnInit {
  form: FormGroup;
  id: string;
  constructor(
    private fb: FormBuilder,
    protected alert: AlertService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    private anonymousService: AnonymousService
  ) {
    super();
  }

  ngOnInit() {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      this.alert.warning('ID não localizado.');
      this.router.navigate(['/']);
    }
    this.form = this.fb.group({
      senha: [null, Validators.compose([Validators.required])],
      confSenha: [null, Validators.compose([Validators.required])]
    });
  }
  onSubmit() {

    let senha = this.form.controls['senha'].value;
    let confSenha = this.form.controls['confSenha'].value;
    if (senha != confSenha) {
      this.alert.warning("A senha e a confirmação da senha não podem ser diferentes");
      return;
    }

    this.loadingGestores(this.anonymousService, "alterarSenha", [this.id, senha, confSenha])
      .pipe(take(1))
      .subscribe(resp => {
        this.alert.success('Sua senha foi alterada com sucesso.');
        this.router.navigate(['/']);
      });
  }
}
