import { TagProdutoEntity } from "../produto/entities/tag-produto.emtity";

export class ProdutoEntity {
  idInterno: number;
  codigoProduto: string;
  nomeProduto: string;
  idDivisao: number;
  nomeDivisao: string;
  status: string;
  valorUnitario: number;
  estoque: number;
  estoqueVirtual: number;
  selecionado: boolean;
  dataValidade: Date;
  pesoProduto: number;
  largura: number;
  altura: number;
  dimensoes: string;
  comprimento: number;
  cudabemPalete: number;
  cubagemRodoviario: number;
  cubagemTotal: number;
  cubagem: number;
  valorTotalEstoque: number;
  totalProduto: number;
  dataUltimoMovimentoEntrada: Date;
  dataUltimoMovimentoSaida: Date;
  descricaoGrupoProduto: string;
  urlImagemKit: string;
  codigoProdutoKit: number;
  codigoProdutoKitPai: number;
  imagemKit: string;
  nomeCliente: string;
  idCliente: number;
  cubagemPalete: number;
  urlImagem: string;
  notaFiscalEntrada: string;
  imagem: any;
  linhaProduto: string;
  saldoVirtual: number;
  tags: TagProdutoEntity[];
  idProduto: number;
  visualizarDivisao: boolean;
  oportunidade: boolean;
}
