import { FaixaKitEntity } from "./faixa-kit.entity";

export class ConfiguracaoKitEntity {
  codigoConfiguracaoKit: number;
  idCliente: number;
  descricao: string;
  ativo: boolean;
  faixas: FaixaKitEntity[];
  permiteAlteracao: boolean;
}

