import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { AlterarStatusPedidoRequestEntity } from '../entities/Requests/alterar-status-pedido.entity';
import { ConsultarPedidoResponseEntity } from '../entities/Responses/consultar-pedido.response.entity';
import { PedidoEntity } from '../entities/pedido.entity';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { FiltroPedidoComponent } from './filtro-pedido/filtro-pedido.component';
import { PedidoService } from './pedidos.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent extends ComponentBase implements OnInit {
  pedidos: ConsultarPedidoResponseEntity[] = [];
  showMarca: boolean;
  modal: NgbModalRef;
  pedido: PedidoEntity;
  situacaoPedido: any;
  status: any = [];
  pesoCubado: number;
  observacao: string;

  @Input() aprovacaoPedido: boolean = false;
  @ViewChild(FiltroPedidoComponent, { static: true }) filtro: FiltroPedidoComponent;
  @ViewChild("modalSituacao", { static: true }) modalSituacao: any;

  constructor(
    protected alert: AlertService,
    protected modalService: NgbModal,
    private route: Router,
    private pedidoService: PedidoService,
    private session: SessionService
  ) { super(); }

  ngOnInit() {
    this.showMarca = !this.session.empresa;
    if (this.session.usuario.acessoNovo) {
      this.loading2<any>(() => this.pedidoService.listarSituacaoPedido())
        .pipe(take(1))
        .subscribe((resp) => {
          console.log(resp);
          this.status = resp;
        })
    }
    super.ngOnInit();
  }

  onFilter(pedidos: ConsultarPedidoResponseEntity[]) {
    if (pedidos.length == 0) {
      this.pedidos = [];
      this.alert.warning("Nenhum pedido foi localizado com base nos parâmetros informados");
      this.clearDataTable("dtPedidos");
      return;
    }

    console.log(pedidos);

    this.pedidos = pedidos;
    this.refreshDataTable('dtPedidos');
  }


  getImagemMarca(pedido: ConsultarPedidoResponseEntity) {
    var empresa = this.session.usuario.empresas.find(x => x.idCliente == pedido.idCliente);
    return this.session.logoClientes + empresa.nomeCliente + ".png";
  }

  permiteAprovacao(divisao: string): boolean {
    let ret = this.session.divisoesAprovaPedido.findIndex((x) => x.nomeDivisao.toLowerCase() === divisao.toLowerCase());
    return ret > -1;
  }


  aprovarPedido(pedido: ConsultarPedidoResponseEntity, index: number) {
    if (!this.permiteAprovacao(pedido.pessoaDivisao)) {
      this.alert.warning("Seu usuário não possui permissão para aprovar pedidos dessa divisão.")
      return;
    }

    this.alert.confirm("Tem certeza que deseja APROVAR o pedido Nº " + pedido.codigoPedido)
      .then((result) => {
        if (result) {
          var obj = new AlterarStatusPedidoRequestEntity();
          obj.empresas = this.session.getIdsEmpresa();
          obj.codigoPedidoAntigo = pedido.codigoPedido;
          obj.usuarioAlteracaoStatus = this.session.usuario;

          this.loadingGestores<any>(this.pedidoService, "aprovarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.pedidos.splice(index, 1);
              this.refreshDataTable('dtPedidos');
              this.alert.info(`Pedido Nº ${pedido.codigoPedido} aprovado com sucesso.`);
            });
        }
      });
  }

  reprovarPedido(pedido: ConsultarPedidoResponseEntity, index: number) {
    if (!this.permiteAprovacao(pedido.pessoaDivisao)) {
      this.alert.warning("Seu usuário não possui permissão para reprovar pedidos dessa divisão.")
      return;
    }
    this.alert.confirm("Tem certeza que deseja REPROVAR o pedido Nº " + pedido.codigoPedido)
      .then((result) => {
        if (result) {
          var obj = new AlterarStatusPedidoRequestEntity();
          obj.empresas = this.session.getIdsEmpresa();
          obj.codigoPedidoAntigo = pedido.codigoPedido;
          obj.usuarioAlteracaoStatus = this.session.usuario;
          this.loadingGestores<any>(this.pedidoService, "reprovarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.pedidos.splice(index, 1);
              this.refreshDataTable('dtPedidos');
              this.alert.info(`Pedido Nº ${pedido.codigoPedido} reprovado com sucesso.`);
            });
        }
      });
  }

  cancelarPedido(pedido: ConsultarPedidoResponseEntity, index: number) {
    if (!this.permiteAprovacao(pedido.pessoaDivisao)) {
      this.alert.warning("Seu usuário não possui permissão para cancelar pedidos dessa divisão.")
      return;
    }

    this.alert.confirm("Tem certeza que deseja CANCELAR o pedido Nº " + pedido.codigoPedido)
      .then((result) => {
        if (result) {
          var obj = new AlterarStatusPedidoRequestEntity();
          obj.empresas = this.session.getIdsEmpresa();
          obj.codigoPedidoAntigo = pedido.codigoPedido;
          obj.usuarioAlteracaoStatus = this.session.usuario;
          this.loadingGestores<any>(this.pedidoService, "cancelarPedido", [obj])
            .pipe(take(1))
            .subscribe(resp => {
              this.pedidos.splice(index, 1);
              this.refreshDataTable('dtPedidos');
              this.alert.info(`Pedido Nº ${pedido.codigoPedido} cancelado com sucesso.`);
            });
        }
      });
  }

  detail(pedido: ConsultarPedidoResponseEntity) {
    var tipo = this.aprovacaoPedido ? "P" : "C";
    var codigo = pedido.codigoPedido == 0 ? pedido.idOrdemSaida : pedido.codigoPedido;
    var url = `/pedidos/detalhepedido/${codigo}/${tipo}`;
    this.session.pedidoSelecionado = pedido;

    this.route.navigate([url]);
  }

  obterCorStatus(status: string): string {
    let cores = new Array(
      { cor: "label label-lg font-weight-bold  label-light-dark label-inline", descricao: "Cancelado" },
      { cor: "label label-lg font-weight-bold  label-light-warning label-inline", descricao: "Aguardando Aprovação" },
      { cor: "label label-lg font-weight-bold  label-light-danger label-inline", descricao: "Reprovado" },
      { cor: "label label-lg font-weight-bold  label-light-warning label-inline", descricao: "Aguardando Separação" },
      { cor: "label label-lg font-weight-bold  label-light-info label-inline", descricao: "Em separação" },
      { cor: "label label-lg font-weight-bold  label-light-warning label-inline", descricao: "Aguardando Expedição" },
      { cor: "label label-lg font-weight-bold  label-light-info label-inline", descricao: "Expedido" },
      { cor: "label label-lg font-weight-bold  label-light-info label-inline", descricao: "Saiu para Entrega" },
      { cor: "label label-lg font-weight-bold  label-light-success label-inline", descricao: "Entregue" },
      { cor: "label label-lg font-weight-bold  label-light-warning label-inline", descricao: "Reentrega" },
      { cor: "label label-lg font-weight-bold  label-light-danger label-inline", descricao: "Devolução" },
      { cor: "label label-lg font-weight-bold  label-light-danger label-inline", descricao: "Devolvido" }
    );
    let obj = cores.find(x => x.descricao.toLowerCase() == status.toLowerCase());
    if (obj == null)
      return "label label-lg font-weight-bold  label-light-dark label-inline";

    return obj.cor;

  }

  visualizarDeclaracaoTransporte(idDeclaracao: string) {
    this.route.navigate(['/pedidos/declaracao/' + idDeclaracao]);
  }

  imprimirPedido(idDeclaracao: string) {
    window.open('/pedidos/pedido/' + idDeclaracao);
  }

  abrirModalSituacao(pedido: PedidoEntity) {
    const id = this.status.filter(x => x.descricao === pedido.status).codigoPedido;
    this.pedido = pedido;
    this.situacaoPedido = id;
    this.pesoCubado = pedido.cubagem;
    this.modal = this.modalService.open(this.modalSituacao, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal", size: 'sm' });
  }

  salvar() {
    const obj = new AlterarStatusPedidoRequestEntity();
    obj.codigoPedido = this.pedido.codigoPedido;
    obj.usuarioAlteracaoStatus = this.session.usuario;
    obj.situacaoPedido = this.situacaoPedido;
    obj.pesoCubado = this.pesoCubado;
    obj.observacao = this.observacao;

    this.loading2<void>(() => this.pedidoService.alterarStatusPedido(obj))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.info("Status do pedido alterado com sucesso.");
        this.modal.close();
        $("#btnPesquisar").click();

      });
  }
}
