import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { IncluirEntradaProdutoSemNfRequestEntity } from '../../../entities/Requests/incluir-entrada-produto-sem-nf-request.entity';
import { ProdutoEntity } from '../../../entities/produto.entity';
import { AlertService } from '../../../shared/alert.service';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';
import { ProdutoService } from '../../produto.service';
import { EntradaProdutoDadosNfComponent } from '../entrada-produto-dados-nf/entrada-produto-dados-nf.component';

@Component({
  selector: 'app-entrada-produto-sem-nf',
  templateUrl: './entrada-produto-sem-nf.component.html',
  styleUrls: ['./entrada-produto-sem-nf.component.css']
})
export class EntradaProdutoSemNfComponent extends ComponentBase implements OnInit {

  @ViewChild(EntradaProdutoDadosNfComponent, { static: false }) entradaProdutoDadosNfRef: EntradaProdutoDadosNfComponent;

  constructor(
    private toast: ToastrService,
    public session: SessionService,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected router: Router

  ) {
    super();
  }

  ngOnInit() {

    if (!this.session.empresa) {
      this.alert.warning("Você precisa selecionar uma empresa para realizar a entrada de produtos.")
        .then(() => {
          this.session.urlReturn = window.location.href;
          this.router.navigate(['empresa/selecionar']);
        });

      return;
    }

    if (this.session.produtosSelecionados && this.session.produtosSelecionados.length > 0) {
      (<any>$("#tab1")).removeClass("active");
      (<any>$("#tabContent1")).removeClass("active");
      (<any>$("#tab2")).addClass("active");
      (<any>$("#tabContent2")).addClass("active");
    } else {
      this.session.produtosSelecionados = [];
    }
  }

  onAddRemove(produto: ProdutoEntity) {
    if (produto.selecionado) {
      const produtos = this.session.produtosSelecionados;
      produtos.push(<IncluirEntradaProdutoSemNfRequestEntity>produto);
      this.session.produtosSelecionados = produtos;
      this.toast.success("Produto adicionado com sucesso.", "Imperium Logística");
    }
    else {
      this.removerProduto(produto);
      this.toast.success("Produto removido com sucesso.", "Imperium Logística");
    }
  }

  removerProduto(produto: ProdutoEntity) {
    const produtos = this.session.produtosSelecionados;
    let idx = produtos.findIndex(x => x.idInterno === produto.idInterno);
    produtos.splice(idx, 1);

    this.session.produtosSelecionados = produtos;
  }

  validarNotaFiscal() {
    if (!this.entradaProdutoDadosNfRef.validarNotaFiscal())
      return;

    (<any>$("#tab0")).removeClass("active");
    (<any>$("#tabContent0")).removeClass("active");
    (<any>$("#tab1")).addClass("active");
    (<any>$("#tabContent1")).addClass("active");
  }

  validarProdutos() {
    if (this.session.produtosSelecionados.length === 0) {
      this.alert.warning("Você precisa selecionar ao menos um produto para realizar a entrada")
      return;
    }

    (<any>$("#tab1")).removeClass("active");
    (<any>$("#tabContent1")).removeClass("active");
    (<any>$("#tab2")).addClass("active");
    (<any>$("#tabContent2")).addClass("active");
  }

  voltarMeusProdutos() {
    (<any>$("#tab2")).removeClass("active");
    (<any>$("#tabContent2")).removeClass("active");
    (<any>$("#tab1")).addClass("active");
    (<any>$("#tabContent1")).addClass("active");
  }

  voltarParaNotaFiscal() {
    (<any>$("#tab1")).removeClass("active");
    (<any>$("#tabContent1")).removeClass("active");
    (<any>$("#tab0")).addClass("active");
    (<any>$("#tabContent0")).addClass("active");
  }

  incluirSolicitacao() {
    for (let x = 0; x < this.session.produtosSelecionados.length; x++) {
      const produto = this.session.produtosSelecionados[x];

      if (!produto.lote) {
        const date = new Date();
        produto.lote = `LT-${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
      }

      if (!produto.quantidade) {
        this.alert.warning(`Indique a quantidade de entrada do produto ${produto.nomeProduto}`);
        return;
      }

      if (produto.quantidade <= 0) {
        this.alert.warning(`Quantidade de entrada do produto ${produto.nomeProduto} não pode ser igual ou menor que zero`);
        return;
      }

      if (!produto.valorUnitario) {
        this.alert.warning(`Indique o valor unitário do produto ${produto.nomeProduto}`);
        return;
      }

      if (produto.valorUnitario <= 0) {
        this.alert.warning(`Valor unitário do produto ${produto.nomeProduto} não pode ser igual ou menor que zero`);
        return;
      }

      if (!produto.pesoProduto) {
        this.alert.warning(`Indique o peso em kilos do produto ${produto.nomeProduto}`);
        return;
      }

      if (produto.pesoProduto <= 0) {
        this.alert.warning(`Peso do produto ${produto.nomeProduto} não pode ser igual ou menor que zero`);
        return;
      }
    }

    this.alert.confirm("Tem certeza que deseja efetivar a entrada dos produtos?").then((val) => {
      if (val.value) {

        this.loading2<void>(() => this.produtoService.incluirEntradaProduto(this.session.produtosSelecionados))
          .pipe(take(1))
          .subscribe(() => {
            this.alert.info('Solicitação realizada com sucesso.');
            this.session.produtosSelecionados = [];
            this.voltar();
          });
      }
    })
  }

  voltar() {
    this.router.navigate(['produtos/entrada-produto']);
  }
}
