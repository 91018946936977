import { EnderecoEntity } from "./endereco.entity";
import { GerarCotacaoResponseEntity } from "./Responses/gerar-cotacao-response.entity";

export class PessoaEntity {
  idCliente: number;
  codigoPessoa: number;
  codigoDestinatarioWeb: number;
  cpfCnpj: string;
  nome: string;
  endereco: string;
  bairro: string;
  cidade: string;
  cep: string;
  uf: string;
  selecionado: boolean;
  prazo: Date;
  enderecoEntregaPedido: EnderecoEntity;
  responsavelPedido: string;
  codigoModal: string;
  idCidade: number;
  cotacaoFrete: GerarCotacaoResponseEntity;
  tipoPessoa: string;
}
