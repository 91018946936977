import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ConfiguracaoKitEntity } from '../../../entities/configuracao-kit.entity';
import { FaixaKitEntity } from '../../../entities/faixa-kit.entity';
import { ProdutoKitEntity } from '../../../entities/produto-kit.entity';
import { ProdutoEntity } from '../../../entities/produto.entity';
import { AlertService } from '../../../shared/alert.service';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';
import { ProdutoService } from '../../produto.service';

@Component({
  selector: 'app-configuracao-kit-produto-formulario',
  templateUrl: './configuracao-kit-produto-formulario.component.html',
  styleUrls: ['./configuracao-kit-produto-formulario.component.css']
})
export class ConfiguracaoKitProdutoFormularioComponent extends ComponentBase implements OnInit {

  faseInicial: string;
  descricaoNovaFase: string;
  divisoesSelecionadas: string;
  config: ConfiguracaoKitEntity = new ConfiguracaoKitEntity();
  faixa: FaixaKitEntity = new FaixaKitEntity();
  faixas: FaixaKitEntity[] = [];
  modal: NgbModalRef;
  codigoKitPai: number = null;
  nivel: number = 0;
  arquivoImagem: any;
  produtoSelecionado: ProdutoKitEntity;
  imagens: any[] = [];
  produtosFaixaSelecionada: ProdutoKitEntity[];
  faixaSelecionada: FaixaKitEntity;
  id: string;
  @ViewChild('modalResultado', { static: true }) modalResultado: TemplateRef<any>;

  constructor(
    protected modalService: NgbModal,
    protected alert: AlertService,
    protected router: Router,
    private toast: ToastrService,
    private session: SessionService,
    private produtoService: ProdutoService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    if (!this.session.empresa) {
      this.alert.warning("Não é possível configurar um Kit para multiplas empresas. Selecione uma empresa e tente novamente");
      return;
    }
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.loadingGestores<ConfiguracaoKitEntity>(this.produtoService, "consultarConfiguracaoKit", [this.id])
        .pipe(take(1))
        .subscribe(resp => {
          this.config = resp;


          this.loadingGestores<FaixaKitEntity[]>(this.produtoService, "consultarFaixaKit", [this.id])
            .pipe(take(1))
            .subscribe(faixas => {
              this.faixas = faixas;
              if (faixas.length > 0)
                this.faixa = faixas[0];
            });
        });
    } else {
      let json = window.localStorage.getItem("configKit");
      if (json) {
        this.config = JSON.parse(json);
        this.faixas = this.config.faixas;
        this.faixa = this.faixas[0];
      }
      this.config.codigoConfiguracaoKit = 0;
    }
  }

  modalDetalheProduto(modalProduto: any, idx: number) {
    this.faixa = this.faixas[idx];
    this.modal = this.modalService.open(modalProduto, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  abrirModalProdutosFaixa(modalProdutosFaixa: any, faixa: FaixaKitEntity) {
    this.produtosFaixaSelecionada = faixa.produtos;
    this.faixaSelecionada = faixa;
    this.modal = this.modalService.open(modalProdutosFaixa, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  onAddRemove(produto: ProdutoEntity, idx: number) {
    if (produto.selecionado) {
      if (!this.faixa.produtos)
        this.faixa.produtos = [];

      let produtoKit = new ProdutoKitEntity();
      produtoKit.idProduto = produto.idInterno;
      produtoKit.codigoProduto = produto.codigoProduto;
      produtoKit.imagensProduto = [];
      produtoKit.imagensProduto.push(this.getImagemProduto(produto.codigoProduto));
      produtoKit.valorProduto = produto.valorUnitario;
      produtoKit.nomeProduto = produto.nomeProduto;
      produtoKit.codigoProdutoKit = this.codigoKitPai ? Number(this.codigoKitPai.toString() + (this.faixa.produtos.length + 1).toString()) : this.faixa.produtos.length + 1;
      produtoKit.codigoProdutoKitPai = this.codigoKitPai;
      produtoKit.idDivisaoProduto = produto.idDivisao;
      //this.faixa.codigoFaixaKit = this.faixas.length + 1;
      this.faixa.ativo = true;
      this.faixa.codigoConfiguracaoKit = this.config.codigoConfiguracaoKit;

      this.faixa.produtos.push(produtoKit);
      this.toast.success("Produto adicionado com sucesso.");
    }
    else {
      this.removerProduto(produto);
    }
  }

  removerProduto(produto: ProdutoEntity) {
    let idx = this.faixa.produtos.findIndex(x => x.idProduto === produto.idInterno);
    this.faixa.produtos.splice(idx, 1);
    this.toast.success("Produto removido com sucesso.");
  }

  removerProdutoKLit(idx: number) {
    this.faixa.produtos.splice(idx, 1);
    this.toast.success("Produto removido com sucesso.");
  }


  getImagemProduto(codigoProduto: string): string {
    let url = this.session.pathImages;
    return `${url}/${codigoProduto}/0.jpg`;
  }

  configProdutoKit(produto: ProdutoKitEntity) {
    this.arquivoImagem = null;
    this.produtoSelecionado = produto;
    this.modal = this.modalService.open(this.modalResultado, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  addProdutoKit(produto: ProdutoKitEntity) {
    let idxFaixa = -1;//this.faixas.findIndex(x => x.codigoFaixaKit === this.faixa.codigoFaixaKit && x.produtos.findIndex(p => p.idProduto === produto.idProduto));
    this.arquivoImagem = null;
    this.codigoKitPai = produto.codigoProdutoKit;

    if (idxFaixa === -1 || this.faixas.length <= idxFaixa + 1) {
      //add o faixa atual no array
      this.faixas.push(this.faixa);

      //cria nova faixa
      this.faixa = new FaixaKitEntity();
      this.faixa.codigoFaixaKit = this.faixas.length + 1;
      this.faixa.ativo = true;
      this.faixa.codigoConfiguracaoKit = this.config.codigoConfiguracaoKit;


      this.nivel++;
      this.getImagensKit();
    }
    else {
      //this.faixa = new FaixaKitEntity();
      this.faixa = this.faixas[idxFaixa + 1];
    }
  }

  fileChangeImagem(e) {
    this.produtoSelecionado.imagemResultado = e.target.files[0];
    this.preview(e.target.files);
    this.modal.close();
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.alert.warning("Somente arquivos de imagens são permitidos");
      return;
    }

    var reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.arquivoImagem = reader.result;
    }
  }

  alterarImagem() {
    (<any>$("#txtImagem")).click();
  }

  getImagensKit() {
    //
    //this.imagens = [];
    for (let x = 0; x < this.faixas.length; x++)
      this.getImagensKitPai(this.faixas[x].produtos, this.codigoKitPai);
  }

  getImagensKitPai(produtos: ProdutoKitEntity[], codigoKitPai: number) {
    //Filtra todos os produtos pelo pai informado
    let prods = produtos.filter(x => x.codigoProdutoKit === codigoKitPai);

    //Adiciona as Imagens ao resultSet
    for (let x = 0; x < prods.length; x++) {
      if (prods[x].imagemResultado)
        this.getImage(prods[x].imagemResultado);
      else
        this.imagens.push(prods[x].urlImagemResultado);
    }

    //Verifica se o resultado possui pai
    let idxPai = prods.findIndex(x => x.codigoProdutoKitPai);

    //Se o resultado possuir pai, chama o metodo para extrair os filhos
    if (idxPai > -1)
      this.getImagensKitPai(prods, prods[idxPai].codigoProdutoKitPai);
  }

  getImage(file: File) {
    let ret = null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imagens.push(reader.result);
    }
  }

  gotoProduto(idx: number) {
    //let imagens = [];
    //for (var x = 0; x <= idx; x++)
    //  imagens.push(this.imagens[x]);

    let idxFaixa = this.faixas.findIndex(x => x.codigoFaixaKit === this.faixa.codigoFaixaKit);
    if (idxFaixa === -1)
      this.faixas.push(this.faixa);

    this.faixa = this.faixas[idx];
    //is.imagens = imagens;
  }

  salvarRascunho() {
    this.config.faixas = this.faixas;
    let json = JSON.stringify(this.config);
    window.localStorage.setItem("configKit", json);
    this.toast.success('Rascunho das configurações de kit salvo com sucesso.');
  }

  gravar() {
    if (!this.session.empresa) {
      this.alert.warning("Não é possível configurar um Kit para multiplas empresas. Selecione uma empresa e tente novamente");
      return;
    }

    let faixa = new FaixaKitEntity();
    faixa.codigoFaixaKit = this.faixas.length + 1;
    faixa.ativo = true;
    faixa.codigoConfiguracaoKit = this.config.codigoConfiguracaoKit;
    faixa.descricao = this.faseInicial;

    this.faixas.push(faixa);

  }

  novaFase() {
    let faixa = new FaixaKitEntity();
    faixa.codigoFaixaKit = this.faixas.length + 1;
    faixa.ativo = true;
    faixa.codigoConfiguracaoKit = this.config.codigoConfiguracaoKit;
    faixa.descricao = this.descricaoNovaFase;

    this.faixas.push(faixa);
  }

  removerFaseKit(idx: number) {
    this.faixas.splice(idx, 1);
  }

  publicar() {
    if (!this.session.empresa) {
      this.alert.warning("Não é possível configurar um Kit para multiplas empresas. Selecione uma empresa e tente novamente");
      return;
    }

    this.config.idCliente = this.session.empresa.idCliente;

    this.config.faixas = this.faixas;
    let metodo = "";
    if (this.id)
      metodo = "alterarConfiguracaoKit";
    else
      metodo = "inserirConfiguracaoKit";

    this.loadingGestores<any>(this.produtoService, metodo, [this.config])
      .pipe(take(1))
      .subscribe(resp => {
        this.toast.success('Kit configurado com sucesso.');
        this.router.navigate(['/produtos/configurar-kit']);
      });
  }

  removerProdutoFaixa(idx: number) {
    this.faixaSelecionada.produtos.splice(idx, 1);
  }
}
