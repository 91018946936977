import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from '../shared/component-base';

@Component({
  selector: 'app-anonynoums',
  templateUrl: './anonynoums.component.html',
  styleUrls: ['./anonynoums.component.css']
})
export class AnonynoumsComponent extends ComponentBase implements OnInit {

  @Input() longPanel:boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  getCorPrincipal(){
    if (window.location.href.indexOf('tanamao') > -1) {
      this.corPrincipal = '#1d7096';
      this.corSecundaria = '#1d7096';
      this.urlLogo = 'https://intellitouch.s3.sa-east-1.amazonaws.com/imperiumlog/marca_tanamao.png';
      this.urlLogoAcesso = 'https://intellitouch.s3.sa-east-1.amazonaws.com/imperiumlog/marca_tanamao.png';
    }

    console.log(this.corPrincipal);
    return this.corPrincipal;
  }
}
