import { ProdutoKitEntity } from "./produto-kit.entity";

export class FaixaKitEntity {
  codigoFaixaKit: number;
  codigoConfiguracaoKit: number;
  descricao: string;
  obrigatorio: boolean;
  qtdMinimo: number;
  qtdMaximo: number;
  ordem: number;
  ativo: boolean;
  produtos: ProdutoKitEntity[];
  completo: boolean;
  posX: number;
  posY: number;
  alturaImagem: number;
  larguraImagem: number;
  imgProdutoSelecionado: string;  
}

