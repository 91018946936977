import { ProdutoPedidoEntity } from "../produto-pedido.entity";

export class ConsultarPedidoDeclaracaoTransporteResponseEntity {
  numeroDeclaracao: number;
  nomeEmpresa: string;
  cnpjEmpresa: string;
  telefoneEmpresa: string;
  enderecoEntrega: string;
  destinatario: string;
  aosCuidados: string;
  modal: string;
  divisao: string;
  solicitante: string;
  pesoCubado: number;
  valorNotaFiscal: number;
  produtos: ProdutoPedidoEntity[];
}
