import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top5-pedidos-coordenador',
  templateUrl: './top5-pedidos-coordenador.component.html',
  styleUrls: ['./top5-pedidos-coordenador.component.css']
})
export class Top5PedidosCoordenadorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
