import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { BreadCrumbEntity } from '../../../entities/bread-crumb.entity';
import { PessoaJuridicaService } from '../../../pessoa/services/pessoa-juridica.service';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css']
})
export class BreadCrumbComponent extends ComponentBase implements OnInit {
  breadcrumbs: Array<BreadCrumbEntity> = [];
  tituloPagina: string = "Imperium Logística";
  imagem: SafeResourceUrl;

  get logoPath(): string {
    if (this.session.empresa)
      return this.session.logoClientes + this.session.empresa.nomeCliente + ".png";

    return null;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public session: SessionService,
    private pessoaJuridicaService: PessoaJuridicaService,
    private sanitizer: DomSanitizer,
    protected router: Router
  ) { super(); }

  ngOnInit() {
    this.tituloPagina = this.activatedRoute.routeConfig.data['label'];
    this.breadcrumbs.push(<BreadCrumbEntity>this.activatedRoute.routeConfig.data);
    this.consultarImagem();
  }

  errorImage(imgLogo: any) {
    imgLogo.hidden = true;
  }

  loadImage(imgLogo: any) {
    imgLogo.hidden = false;
  }

  consultarImagem(): void {
    if (this.session.usuario.acessoNovo && this.session.empresa) {
      this.loading2<string>(() => this.pessoaJuridicaService.consultarImagem(this.session.empresa.idCliente))
        .pipe(take(1))
        .subscribe(resp => {
          const sanitizer = this.sanitizer;
          if (resp) {
            this.imagem = sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp);
          }
        });
    }
  }
}
