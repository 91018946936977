import { ProdutoEntity } from "./produto.entity";
import { ProdutoKitEntity } from "./produto-kit.entity";

export class ItemCarrinhoEntity {
  produto: ProdutoEntity;
  quantidade: number;
  isKit: boolean;
  codigoProdutoKit: number;
  codigoConfiguracaoKit: number;
  produtosKit: ProdutoKitEntity[];

  get valorItem(): number {    
    return this.produto.valorUnitario * this.quantidade;
  }
}
