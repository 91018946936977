import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { EnderecoEntity } from '../../entities/endereco.entity';
import { PessoaEntity } from '../../entities/pessoa.entity';
import { CepService } from '../../shared/cep.service';
import { ComponentBase } from '../../shared/component-base';
import { DestinatarioService } from '../destinatario.service';


const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TableDestinatarioComponent),
  multi: true
};

@Component({
  selector: 'app-table-destinatario',
  templateUrl: './table-destinatario.component.html',
  styleUrls: ['./table-destinatario.component.css'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class TableDestinatarioComponent extends ComponentBase implements OnInit, ControlValueAccessor {

  constructor(
    private toast: ToastrService,
    private destinatario: DestinatarioService,
    protected modalService: NgbModal,
    private cepService: CepService,
  ) { super(); }

  modal: NgbModalRef;
  pessoaAlterarEndereco: PessoaEntity;
  pessoaAntigo: PessoaEntity;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: PessoaEntity[]) => void = noop;
  pessoas: PessoaEntity[];

  @Input() showAddRemove: boolean = false;
  @Output() onAddRemoveItem: EventEmitter<PessoaEntity> = new EventEmitter();
  @ViewChild("modalAlterarEndereco", { static: true }) modalAlterarEndereco: any;

  ngOnInit() {
    super.ngOnInit();
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  alterarEndereco(pessoa: PessoaEntity) {
    if (this.possuiAcessoRecurso(78) && pessoa.tipoPessoa === "F") {
      this.pessoaAntigo = { ...pessoa };
      this.pessoaAlterarEndereco = pessoa;
      this.abrirModal();
    }
  }

  abrirModal() {
    this.modal = this.modalService.open(this.modalAlterarEndereco, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  cancelar() {
    this.pessoaAlterarEndereco = this.pessoaAntigo;
    this.modal.close();
  }

  salvar() {
    this.pessoaAlterarEndereco.enderecoEntregaPedido = new EnderecoEntity();
    this.pessoaAlterarEndereco.enderecoEntregaPedido.cep = this.pessoaAlterarEndereco.cep;
    this.pessoaAlterarEndereco.enderecoEntregaPedido.bairro = this.pessoaAlterarEndereco.bairro;
    this.pessoaAlterarEndereco.enderecoEntregaPedido.cidade = this.pessoaAlterarEndereco.cidade;
    this.pessoaAlterarEndereco.enderecoEntregaPedido.logradouro = this.pessoaAlterarEndereco.endereco;
    this.pessoaAlterarEndereco.enderecoEntregaPedido.uf = this.pessoaAlterarEndereco.uf;

    this.modal.close();
  }

  pesquisarCep() {
    if (!this.pessoaAlterarEndereco.cep)
      return;
    this.loadingGestores(this.cepService, "consultarCep", [this.pessoaAlterarEndereco.cep])
      .pipe(take(1))
      .subscribe(resp => {


        // Altera o endereço de visualização
        this.pessoaAlterarEndereco.endereco = resp['logradouro'];
        this.pessoaAlterarEndereco.bairro = resp['bairro'];
        this.pessoaAlterarEndereco.cidade = resp['localidade'];
        this.pessoaAlterarEndereco.uf = resp['uf'];

        // // Registra o endereço de entrega
        // this.pessoaAlterarEndereco.enderecoEntregaPedido.cep = this.pessoaAlterarEndereco.cep;
        // this.pessoaAlterarEndereco.enderecoEntregaPedido.bairro = resp['bairro'];
        // this.pessoaAlterarEndereco.enderecoEntregaPedido.cidade = resp['localidade'];
        // this.pessoaAlterarEndereco.enderecoEntregaPedido.logradouro = resp['logradouro'];
        // this.pessoaAlterarEndereco.enderecoEntregaPedido.uf = resp['uf'];
      });
  }

  // Evento acionado quando ocorre modificação na model
  writeValue(value: PessoaEntity[]) {
    console.log(value);
    if (!value || value.length == 0) {
      this.clearDataTable('dtDestinatarios');
      this.pessoas = [];

      return;
    }
    if (value !== this.pessoas) {
      this.pessoas = this.destinatario.montarListaPessoas(value);
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
    this.refreshDataTable('dtDestinatarios');
  }

  addRemoveDestinatario(pessoa: PessoaEntity, idx: number) {
    if (pessoa.selecionado) {
      this.destinatario.removeDestinatario(pessoa);
      this.toast.success('Destinatário removido com sucesso.', 'Destinatários', {
        progressBar: true,
        positionClass: 'toast-top-left'
      });
    }
    else {
      this.destinatario.addDestinatario(pessoa);
      this.toast.success('Destinatário adicionado com sucesso.', 'Destinatários', {
        progressBar: true,
        positionClass: 'toast-top-left'
      });

      this.onAddRemoveItem.emit(pessoa);
    }
  }
}
