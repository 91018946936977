import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SwitchComponent),
  multi: true
};

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
  @Input() isChecked: boolean;
  @Input() disabled: boolean;
  @Input() title: string;
  @Output() changeChecked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onChange = (checked: boolean) => { };

  onTouched = () => { };

  get value(): boolean {
    return this.isChecked;
  }

  check(): void {
    const check = !this.isChecked;
    this.writeValue(check);
    this.changeChecked.emit(check);
  }

  writeValue(checked: boolean): void {
    this.onChange(checked);
  }

  registerOnChange(fn: (checked: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
