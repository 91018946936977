import { ItemCarrinhoEntity } from "../item-carrinho.entity";
import { PessoaEntity } from "../pessoa.entity";
import { UsuarioLogadoEntity } from "../usuario-logado.entity";

export class GravarPedidoRequestEntity {
  codigoCliente: number;
  codigoUsuarioSolicitante: number;
  solicitante: UsuarioLogadoEntity;
  itensCarrinho: ItemCarrinhoEntity[];
  destinatarios: PessoaEntity[];
  observacao: string;
  empresas:string;
}
