import { DivisaoESLEntity } from "./divisao-esl.entity";
import { EmpresaEntity } from "./empresa.entity";
import { RecursoEntity } from "./recurso.entity";

export class UsuarioLogadoEntity {
  idPessoa: number;
  nomePessoa: string;
  idCliente: number;
  telefone: string;
  email: string;
  tpCliente: boolean;
  divisoes: DivisaoESLEntity[];
  funcionario: boolean;
  atualizarDadosPessoais: boolean;
  empresas: EmpresaEntity[];
  idsEmpresas: string;
  recursos: RecursoEntity[];
  acessoNovo: boolean;
  agruparDivisao: boolean;
}
