import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { DivisaoComponent } from '../../divisao/divisao.component';
import { ConsultarPedidoRequestEntity } from '../../entities/Requests/consultar-pedido-request.entity';
import { ConsultarPedidoResponseEntity } from '../../entities/Responses/consultar-pedido.response.entity';
import { CampanhaEntity } from '../../entities/campanha.entity';
import { StatusPedido } from '../../entities/status-pedido.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { PedidoService } from '../pedidos.service';

@Component({
  selector: 'app-filtro-pedido',
  templateUrl: './filtro-pedido.component.html',
  styleUrls: ['./filtro-pedido.component.css']
})
export class FiltroPedidoComponent extends ComponentBase implements OnInit {
  consulta: ConsultarPedidoRequestEntity = new ConsultarPedidoRequestEntity();
  statusPedido: StatusPedido[] = [];
  campanhas: CampanhaEntity[];

  @Output() onFilter: EventEmitter<ConsultarPedidoResponseEntity[]> = new EventEmitter();
  @Input() aprovacaoPedido: boolean = false;
  @ViewChild(DivisaoComponent, { static: true }) refDivisao: DivisaoComponent;

  constructor(
    private pedidoService: PedidoService,
    private session: SessionService
  ) { super(); }

  ngOnInit() {
    this.statusPedido = this.obterStatusPedido();

    if (this.aprovacaoPedido)
      this.consulta.statusPedido = 2;

    this.carregarCampanhas();
  }

  obterStatusPedido(): StatusPedido[] {
    return new Array(
      { codigo: 1, descricao: "Cancelado" },
      { codigo: 2, descricao: "Aguardando Aprovação" },
      { codigo: 3, descricao: "Reprovado" },
      { codigo: 4, descricao: "Aguardando Separação" },
      { codigo: 5, descricao: "Em separação" },
      { codigo: 6, descricao: "Aguardando Expedição" },
      { codigo: 7, descricao: "Expedido" },
      { codigo: 8, descricao: "Entregue" },
      { codigo: 9, descricao: "Reentrega" },
      { codigo: 10, descricao: "Devolução" },
    );
  }

  limpar() {
    this.consulta = new ConsultarPedidoRequestEntity();
    if (this.aprovacaoPedido)
      this.consulta.statusPedido = 2;
  }

  pesquisar() {

    this.consulta.usuarioLogado = this.session.usuario;
    if (this.refDivisao && this.refDivisao.obterEmpresaSelecionada())
      this.consulta.empresas = this.refDivisao.obterEmpresaSelecionada();
    else
      this.consulta.empresas = this.session.getIdsEmpresa();

      if (this.session.usuario.agruparDivisao) {
       this.consulta.divisoesSelecionadas = this.refDivisao.idsDivisoes;
      }
    this.loadingGestores<ConsultarPedidoResponseEntity[]>(this.pedidoService, "consultarPedido", [this.consulta])
      .pipe(take(1))
      .subscribe(resp => {
        this.onFilter.emit(resp);
      });
  }

  carregarCampanhas() {
    let empresa = "";
    if (this.session.empresa)
      empresa = this.session.empresa.idCliente.toString();
    else if (this.refDivisao && this.refDivisao.obterEmpresaSelecionada())
      empresa = this.refDivisao.obterEmpresaSelecionada();
    else
      empresa = this.session.getIdsEmpresa();

    this.loadingGestores<CampanhaEntity[]>(this.pedidoService, "consultarCampanhas", [empresa])
      .pipe(take(1))
      .subscribe(resp => {
        this.campanhas = resp;
      });
  }
}
