import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '../layout/layout.module';
import { AjudaComponent } from './ajuda.component';


@NgModule({
  declarations: [AjudaComponent],
  exports: [AjudaComponent],
  imports: [
    CommonModule,
    LayoutModule
  ]
})
export class AjudaModule { }
