import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriaComponent } from './categoria/categoria.component';
import { ConfigurarRelatorioComponent } from './configurar-relatorio/configurar-relatorio.component';
import { EnviosComponent } from './envios/envios.component';
import { RecebimentosComponent } from './recebimentos/recebimentos.component';
import { ReportDynamicComponent } from './report-dynamic/report-dynamic.component';


const routes: Routes = [
  {
    path: 'envios',
    component: EnviosComponent,
    data: { label: 'Relatório de Envios', url: '/relatorioenvios', middlePath: 'Relatórios' }
  }, {
    path: 'recebimentos',
    component: RecebimentosComponent,
    data: { label: 'Relatório de Recebimentos', url: '/relatoriorecebimentos', middlePath: 'Relatórios' }
  },
  {
    path: 'gerencial',
    component: ReportDynamicComponent,
    data: { label: 'Relatório Gerenciais', url: '/relatorios-gerenciais', middlePath: 'Relatórios' }
  },
  {
    path: 'configurar-relatorio',
    component: ConfigurarRelatorioComponent,
    data: { label: 'Configurar Relatório Gerenciais', url: '/configurar-relatorio', middlePath: 'Relatórios' }
  },
  {
    path: 'categoria',
    component: CategoriaComponent,
    data: { label: 'Categorias', url: '/categoria', middlePath: 'Relatórios' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule { }
