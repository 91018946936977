import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { FinalizarPedidoComponent } from './finalizar-pedido.component';
import { ItensCarrinhoComponent } from './itens-carrinho/itens-carrinho.component';
import { SelecionarDestinatariosComponent } from './selecionar-destinatarios/selecionar-destinatarios.component';
import { ConferirPedidoComponent } from './conferir-pedido/conferir-pedido.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { RouterModule } from '@angular/router';
import { DestinatarioModule } from '../destinatario/destinatario.module';
import { ListaDestinatarioComponent } from './lista-destinatario/lista-destinatario.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    RouterModule,
    DestinatarioModule,
    PipesModule
  ],
  declarations: [
    FinalizarPedidoComponent,
    ItensCarrinhoComponent,
    SelecionarDestinatariosComponent,
    ConferirPedidoComponent,
    InvoiceComponent,
    ListaDestinatarioComponent
  ],
  exports: [
    FinalizarPedidoComponent,
    ItensCarrinhoComponent,
    SelecionarDestinatariosComponent,
    ConferirPedidoComponent,
    InvoiceComponent
  ]
})
export class FinalizarPedidoModule { }
