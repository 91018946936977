import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DestinatarioService } from '../../destinatario/destinatario.service';
import { LoginEntity } from '../../entities/login.entity';
import { AuthorizationService } from '../../shared/authorization.service';
import { CommonService } from '../../shared/common.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends ComponentBase implements OnInit {

  login: LoginEntity = new LoginEntity();
  form: FormGroup;
  ip: string = "0.0.0.0";

  constructor(
    private authorizationService: AuthorizationService,
    private session: SessionService,
    protected router: Router,
    private common: CommonService,
    private fb: FormBuilder,
    private destintarioService: DestinatarioService
  ) { super(); }

  ngOnInit() {
    this.carregarConfiguracoes();
    this.common.consultarIp()
      .pipe(take(1))
      .subscribe((resp => {
        this.ip = resp._body.ip;
        if (this.session.data) {
          this.acessarSistema();
        }
      }));


    this.form = this.fb.group({
      usuario: [null, Validators.compose([Validators.required])],
      senha: [null, Validators.compose([Validators.required])]
    });
  }

  acessarSistema() {
    if (this.session.data) {
      this.login.usuario = this.session.data.usuario;
      this.login.senha = this.session.data.senha;
      this.session.clear();
    } else {
      this.login.usuario = this.form.controls['usuario'].value;
      this.login.senha = this.form.controls['senha'].value;
    }


    this.login.ip = this.ip;

    this.loadingGestores<any>(this.authorizationService, "autenticar", [this.login])
      .pipe(take(1))
      .subscribe(response => {
        if (this.session.usuario && this.session.usuario['nomeCliente'] != response.usuario['nomeCliente']) {
          this.session.clear();
        }

        localStorage.setItem('token', response['token']);
        this.session.divisoes = response.usuario.divisoes;
        this.session.usuario = response.usuario;
        this.session.pathImages = response.pathImages;
        this.session.logoClientes = response.logoClientes;
        this.session.pathImagesPDF = response.pathImagesPDF;
        this.session.logoClientesPDF = response.logoClientesPDF;

        if (response.usuario.atualizarDadosPessoais)
          this.router.navigate(['/alterar-dados-pessoais']);
        else {
          if (this.session.usuario.empresas.length === 1) {
            this.session.empresa = this.session.usuario.empresas[0];
          }
          this.router.navigate(['/produtos']);
        }

        this.destintarioService.addUsuarioLogadoDestinatario();

      });
  }
}
