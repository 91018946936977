import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ConsultarDadosDashboardRequestEntity } from '../../entities/Requests/consultar-dados-dashboard.request.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-km-rodado-frete-estado',
  templateUrl: './km-rodado-frete-estado.component.html',
  styleUrls: ['./km-rodado-frete-estado.component.css']
})
export class KmRodadoFreteEstadoComponent extends ComponentBase implements OnInit {

  dataset = [];
  totalKm: number = 0;
  totalFrete: number = 0;
  constructor(
    private session: SessionService,
    private dashboardService: DashboardService
  ) {
    super();
  }

  ngOnInit() {
    let entity = new ConsultarDadosDashboardRequestEntity();
    const currentDate = new Date();
    entity.clientes = this.session.getIdsEmpresa();
    entity.divisoes = this.session.getIdDivisoes();
    entity.dataInicial = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
    entity.dataFinal = currentDate;

    this.filtrarDados(entity);
  }

  filtrarDados(entity: ConsultarDadosDashboardRequestEntity) {
    this.loading2<any[]>(() => this.dashboardService.obterKmRodadosFreteCompartilhado(entity))
      .pipe(take(1))
      .subscribe(resp => {
        this.totalFrete = 0;
        this.totalKm = 0;

        for (let x = 0; x < resp.length; x++) {
          this.totalFrete += resp[x].totalFrete;
          this.totalKm += resp[x].kmRodados;
        }

        this.dataset = resp;
      });
  }
}
