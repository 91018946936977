import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simNao'
})
export class SimNaoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 'S')
      return 'SIM';
    else if (value === 'N')
      return 'NÃO';
    else if (value === true)
      return 'SIM';
    else if (value === false)
      return 'NÃO';

    return null;
  }
}
