import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { take } from 'rxjs/operators';
import { ConsultarProdutoRequestEntity } from '../../entities/Requests/consultar-produto-request.entity';
import { FichaProdutoEntity } from '../../entities/ficha-produto.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ConsultarProdutosRelacionadosResponseEntity } from '../entities/response/consultar-produto-relacionados-response.entity';
import { ProdutoRelacionadoComponent } from '../produto-relacionado/produto-relacionado.component';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-detalhe',
  templateUrl: './produto-detalhe.component.html',
  styleUrls: ['./produto-detalhe.component.css']
})
export class ProdutoDetalheComponent extends ComponentBase implements OnInit {

  @ViewChild('imgShowcase', { static: true }) imgShowcase: any;
  @ViewChild(ProdutoRelacionadoComponent, { static: true }) produtoRelacionadoRef: any;

  idProduto: number = 0;
  fichaProduto: FichaProdutoEntity;
  produto: ProdutoEntity;
  quantidade: number = 1;
  produtosRelacionados: ConsultarProdutosRelacionadosResponseEntity[] = [];
  constructor(
    private carrinhoService: CarrinhoService,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    public session: SessionService

  ) {
    super();
  }

  ngOnInit() {

  }

  carregarProdutosRelacionados() {
    this.call2<ConsultarProdutosRelacionadosResponseEntity[]>(() => this.produtoService.consultarProdutosRelacionados(this.session.produtoDetalhe.idInterno))
      .pipe(take(1))
      .subscribe((resp) => {
        this.session.produtosRelacionados = resp;
      });
  }

  clickImage(idx: number) {
    this.imgShowcase.nativeElement.src = $(`#img${idx}`).attr('src');
    console.log(idx);
  }

  voltar() {
    this.session.produtoDetalhe = null;
    this.session.fichaProdutoDetalhe = null;
  }

  addProdutoCarrinho() {

    const produto = this.session.produtoDetalhe;
    if (this.session.empresa && this.session.empresa.produtoESL) {
      var divisao = this.session.consultarDivisaoPorNome(produto.nomeDivisao);

      if (!divisao) {
        this.alert.error("Não foi possível consultar a divisão atual. Tente novamente em alguns instantes.");
        return;
      }

      if (divisao.fazPedidoWeb == false) {
        this.alert.warning(`Seu usuário não pode efetuar pedidos para produtos da divisão ${produto.nomeDivisao}. Contate o seu coordenador.`);
        return;
      }
    }

    this.carrinhoService.adicionarProduto(produto, Number(this.quantidade));
  }

  permiteVisualizarSaldo(): boolean {
    return this.possuiAcessoRecurso(23);
  }

  clickProdutoRelacionado(produto: ConsultarProdutosRelacionadosResponseEntity) {
    const entity = new ConsultarProdutoRequestEntity();
    entity.codigoProduto = produto.codigoProduto;
    entity.divisoes = this.session.divisoes.map(x => x.idPessoaDivisao);
    entity.saldoZerado = true;


    this.call2<ProdutoEntity[]>(() => this.produtoService.consultarProdutos(entity))
      .pipe(take(1))
      .subscribe((resp) => {
        if (!resp || resp.length === 0) {
          this.alert.warning("Produto Indisponível no momento. Entre em contato com o seu gestor ou o administrador do sistema.");
          return;
        }
        this.session.produtoDetalhe = resp[0];
        this.carregarProdutosRelacionados();
      });

    this.call2<FichaProdutoEntity>(() => this.produtoService.consultarFichaProduto(this.session.usuario.idCliente, produto.idProduto))
      .pipe(take(1))
      .subscribe((resp) => {
        this.session.fichaProdutoDetalhe = resp;
      });
  }
}
