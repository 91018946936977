import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProdutoConstant } from '../../Constants/produto.constant';
import { ProdutoEntity } from '../../entities/produto.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TableProduto2Component),
  multi: true
};


@Component({
  selector: 'app-table-produto2',
  templateUrl: './table-produto2.component.html',
  styleUrls: ['./table-produto2.component.css'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class TableProduto2Component extends ComponentBase implements OnInit, ControlValueAccessor {

  showMarca: boolean;

  //#region [ Construtores ]

  constructor(
    public session: SessionService,
    private carrinho: CarrinhoService
  ) { super(); }

  //#endregion

  //#region [ variaveis ]
  @Input() addProdutos: boolean = false;
  @Input() simpleView: boolean = false;
  @Input() exibirSaldoVirtual: boolean = false;

  @Output() detailClick: EventEmitter<ProdutoEntity> = new EventEmitter();
  @Output() transferirDivisaoClick: EventEmitter<ProdutoEntity> = new EventEmitter();
  @Output() transferirLocalizacaoClick: EventEmitter<ProdutoEntity> = new EventEmitter();
  @Output() movimentacaoClick: EventEmitter<ProdutoEntity> = new EventEmitter();
  @Output() onAddRemove: EventEmitter<ProdutoEntity> = new EventEmitter();

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: ProdutoEntity[]) => void = noop;
  produtos: ProdutoEntity[];

  //#endregion

  //#region [ ngOnInit ]
  ngOnInit() {
    this.hideTableButtons = true;
    this.showMarca = !this.session.empresa;
    super.ngOnInit();
  }
  //#endregion

  //#region [ ValueAcessor ]

  // Evento acionado quando ocorre modificação no DOM.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // Evento acionado quando ocorre modificação na model
  writeValue(value: ProdutoEntity[]) {
    if (!value || value.length == 0) {
      this.clearDataTable('dtProdutos');
      this.produtos = [];

      return;
    }
    if (value !== this.produtos) {
      this.produtos = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
    this.refreshDataTable('dtProdutos');
  }
  //#endregion

  //#region [ Métodos Publicos ]
  getCorStatus(status: number): string {
    return ProdutoConstant.coresStatusProduto[status - 1].name;
  }

  getTituloStatus(status: number): string {
    return ProdutoConstant.statusProduto[status].name;
  }

  getImagemProduto(produto: ProdutoEntity): string {
    let url = this.session.pathImages;
    produto.urlImagem = `${url}/${produto.codigoProduto}/0.jpg`;

    return produto.urlImagem;
  }

  getImagemMarca(produto: ProdutoEntity) {
    return this.session.logoClientes + produto.nomeCliente + ".png";
  }

  addProdutoCarrinho(produto: ProdutoEntity) {
    if (!this.session.usuario.acessoNovo) {
      var divisao = this.session.consultarDivisaoPorNome(produto.nomeDivisao);

      if (!divisao) {
        this.alert.error("Não foi possível consultar a divisão atual. Tente novamente em alguns instantes.");
        return;
      }
    }
    if (divisao.fazPedidoWeb == false) {
      this.alert.warning(`Seu usuário não pode efetuar pedidos para produtos da divisão ${produto.nomeDivisao}. Contate o seu coordenador.`);
      return;
    }

    this.carrinho.adicionarProduto(produto);
  }
  //#endregion

  detail(produto: ProdutoEntity) {
    this.detailClick.emit(produto);
  }

  addRemoveProduto(produto: ProdutoEntity) {
    produto.selecionado = !produto.selecionado;
    this.onAddRemove.emit(produto);
  }

  permiteVisualizarSaldo(produto: ProdutoEntity): boolean {
    var divisao = this.session.consultarDivisaoPorNome(produto.nomeDivisao);
    if (divisao)
      return divisao.visualizaQuantidadeDisponivel;

    return true;
  }

  transferirDivisao(produto: ProdutoEntity) {
    this.transferirDivisaoClick.emit(produto);
  }

  transferirLocalizacao(produto: ProdutoEntity) {
    this.transferirLocalizacaoClick.emit(produto);
  }

  verMovimentacao(produto: ProdutoEntity) {
    this.movimentacaoClick.emit(produto);
  }
}
