import { formatNumber } from '@angular/common';
import { ElementRef, Inject, Injectable, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class MaskService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init(mask: string, inputRef: any): void {
    if (mask) {
      if (mask == 'kilo')
        (<any>$(inputRef.nativeElement)).mask('000.000.000.000.000,000', { reverse: true });

      else if (mask == 'decimal')
        (<any>$(inputRef.nativeElement)).mask('000.000.000.000.000,00', { reverse: true });
      else if (mask == 'peso')
        (<any>$(inputRef.nativeElement)).mask('000.000.000.000.000,000', { reverse: true });
      else if (mask == 'cpf-cnpj')
        (<any>$(inputRef.nativeElement)).mask('000.000.000-000');
      else if (mask == 'cpf')
        (<any>$(inputRef.nativeElement)).mask('000.000.000-000');
      else if (mask == 'cnpj')
        (<any>$(inputRef.nativeElement)).mask('00.000.000/0000-00');
      else
        (<any>$(inputRef.nativeElement)).mask(mask);
    }
  }

  // Evento acionado quando ocorre modificação na model
  formatter(mask: string, value: any, inputRef: any): any {
    if (mask && value) {
      if (mask == 'decimal') {
        value = String(value);
        let precision = 2;
        let decimalSeparator = value.substr(value.length - (Number(precision) + 1), 1);
        if (decimalSeparator == "." || decimalSeparator != ",")
          value = formatNumber(value, this.locale, '1.2-2');
      }
      else if (mask == 'peso' || mask === 'kilo') {
        value = String(value);
        let precision = 3;
        let decimalSeparator = value.substr(value.length - (Number(precision) + 1), 1);
        if (decimalSeparator == "." || decimalSeparator != ",")
          value = formatNumber(value, this.locale, '1.3-3');
      }
      else if (mask == 'cpf-cnpj') {
        value = value.replace(/[^\w\s]/gi, '');
        if (value.length > 11)
          (<any>$(inputRef.nativeElement)).mask('00.000.000/0000-00');
        else
          (<any>$(inputRef.nativeElement)).mask('000.000.000-00');

        value = (<any>$(inputRef.nativeElement)).masked(value);
      }
      else if (mask == 'cnpj') {
        value = value.replace(/[^\w\s]/gi, '');
        (<any>$(inputRef.nativeElement)).mask('00.000.000/0000-00');
        value = (<any>$(inputRef.nativeElement)).masked(value);
      }
      else if (mask == 'cpf') {
        value = value.replace(/[^\w\s]/gi, '');
        (<any>$(inputRef.nativeElement)).mask('000.000.000-00');
        value = (<any>$(inputRef.nativeElement)).masked(value);
      }
      else if (mask === 'telefone')
        (<any>$(inputRef.nativeElement)).mask('(00) 0000-0000');
      else
        value = (<any>$(inputRef.nativeElement)).masked(value);
    }

    return value;
  }

  // Evento acionado quando ocorre modificação no DOM.
  // Não realiza modificação na renderização no DOM.
  // Ex:
  //   DOM = 1.000,00 -> 1000.00
  parser(mask: string, value: any, inputRef: ElementRef): any {
    if (value) {
      if (mask == 'decimal') {
        var precision = 2;
        value = value.replace(/[^\d]+/g, ""); // Retira a formatação;
        var index = value.length - precision;
        value = [value.slice(0, index), ".", value.slice(index)].join("");
      }
      else if (mask == 'peso' || mask === 'kilo') {
        var precision = 3;
        value = value.replace(/[^\d]+/g, ""); // Retira a formatação;
        value = Number(value).toString()
        var index = value.length - precision;
        value = [value.slice(0, index), ".", value.slice(index)].join("");
      }
      else if (mask == 'cpf-cnpj') {
        let cpfCnpj = value.replace(/[^\w\s]/gi, '');
        if (cpfCnpj.length > 11)
          (<any>$(inputRef.nativeElement)).mask('00.000.000/0000-00');
        else
          (<any>$(inputRef.nativeElement)).mask('000.000.000-000');
      }
      else if (mask == 'cnpj') {
        (<any>$(inputRef.nativeElement)).mask('00.000.000/0000-00');
      }
      else if (mask == 'cpf') {
        (<any>$(inputRef.nativeElement)).mask('000.000.000-00');
      }
      else if (mask == 'telefone') {
        if (value.length > 5 && value[5] === "9")
          (<any>$(inputRef.nativeElement)).mask('(00) 00000-0000');
        else
          (<any>$(inputRef.nativeElement)).mask('(00) 0000-0000');
      }
    }

    return value;
  }

  // Evento acionado quando ocorre modificação no DOM.
  // Realiza modificação na renderização no DOM.
  // Ex:
  //   DOM = 0,000 -> 0,00
  transform(mask: string, value: any, oldValue: any, inputRef: ElementRef): any {
    if (mask == 'decimal') {
      let val = value.replace(/[^\w\s]/gi, '');
      if (val && Number(val) == 0) {
        value = '0,00';
        this.renderer.setProperty(inputRef.nativeElement, 'value', value);
      }
      else if (value.length == 1)
        value = "0,0" + value;
      else if (value.length == 3 || value.length == 5) {
        let numberValue = Number(value.replace(',', '.'));
        if (oldValue.length <= value.length)
          value = numberValue * 10;
        else
          value = numberValue / 10;

        value = formatNumber(value, this.locale, '1.2-2');
        value = String(value);
      }
    }
    if (mask == 'peso' || mask === 'kilo') {
      let val = value.replace(/[^\w\s]/gi, '');
      if (val && Number(val) == 0) {
        value = '0,000';
        this.renderer.setProperty(inputRef.nativeElement, 'value', value);
      }
      else if (value.length == 1)
        value = "0,00" + value;
      else if (value.length == 4 || value.length == 6) {
        let numberValue = Number(value.replace(',', '.'));
        if (oldValue.length <= value.length)
          if (Number(value).toString().length == 1)
            value = numberValue * 100;
          else
            value = numberValue * 10;
        else
          value = numberValue / 10;

        value = formatNumber(value, this.locale, '1.3-3');
        value = String(value);
      }
    }

    return value;
  }
}
