import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ItemCarrinhoEntity } from '../../../../../entities/item-carrinho.entity';
import { ProdutoService } from '../../../../../produto/produto.service';
import { ComponentBase } from '../../../../../shared/component-base';
import { CarrinhoService } from './carrinho.service';


@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.css']
})
export class CarrinhoComponent extends ComponentBase implements OnInit {
  constructor(
    private carrinho: CarrinhoService,
    private produtoService: ProdutoService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit() {

  }

  getImagemProduto(codigoProduto: string): string {
    return this.produtoService.getImagemProduto(codigoProduto);
  }

  itensCarrinho(): ItemCarrinhoEntity[] {
    return this.carrinho.itens;
  }

  qtdItensCarrinho(): number {
    return this.carrinho.itens.length;
    // return Number(this.carrinho.itens.reduce((accumulator, produto) => {
    //   return accumulator + produto.quantidade;
    // }, 0));
  }

  finalizarPedido() {
    this.router.navigate(['/pedidos/finalizarpedido']);
  }

  getValorCarrinho(): number {
    return this.carrinho.getValorCarrinho();
  }
}
