import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { take } from 'rxjs/operators';
import { ConfiguracaoKitEntity } from '../../entities/configuracao-kit.entity';
import { FaixaKitEntity } from '../../entities/faixa-kit.entity';
import { ItemCarrinhoEntity } from '../../entities/item-carrinho.entity';
import { ProdutoKitEntity } from '../../entities/produto-kit.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-solicitacao-kit-produto',
  templateUrl: './solicitacao-kit-produto.component.html',
  styleUrls: ['./solicitacao-kit-produto.component.css']
})
export class SolicitacaoKitProdutoComponent extends ComponentBase implements OnInit {

  configuracoes: ConfiguracaoKitEntity[];
  faixasKit: FaixaKitEntity[];

  itensCarrinho: ItemCarrinhoEntity[] = [];
  imgResultado: any;
  codigoPaiSelecionado: number;
  faixa: FaixaKitEntity;
  faixaAtual: number = 0;
  produtoSelecionado: ProdutoKitEntity;
  listaProdutos: ProdutoKitEntity[] = [];
  configSelecionada: ConfiguracaoKitEntity;
  textoBotao = "Solicitar Kit";
  processando = false;
  codigoProdutoBase: number = 0;;

  constructor(
    private produtoService: ProdutoService,
    protected alert: AlertService,
    private carrinho: CarrinhoService,
    protected router: Router,
    private sanitization: DomSanitizer,
    private session: SessionService
  ) {
    super();
  }

  ngOnInit() {
    this.carregarConfiguracoesKit();
  }

  carregarConfiguracoesKit() {
    if (!this.session.empresa) {
      this.alert.warning("Para solicitar Kits é necessário ter selecionado uma empresa.")
        .then(() => {
          this.session.urlReturn = window.location.href;
          this.router.navigate(['/empresa/selecionar']);
        });
      return;
    }
    let empresas = "";
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente.toString();

    this.loadingGestores<ConfiguracaoKitEntity[]>(this.produtoService, "listarConfiguracaoKit", [empresas])
      .pipe(take(1))
      .subscribe(resp => {

        this.configuracoes = resp;
      });
  }

  selecionarConfig(config: ConfiguracaoKitEntity) {
    this.configSelecionada = config;
    this.carregarFaixas(config.codigoConfiguracaoKit);
  }

  carregarFaixas(codigoConfiguracaoKit: number) {
    this.loadingGestores<FaixaKitEntity[]>(this.produtoService, "consultarFaixaKit", [codigoConfiguracaoKit])
      .pipe(take(1))
      .subscribe(resp => {

        this.faixasKit = resp;
        this.faixa = resp[0];
        if (this.faixa.produtos.length === 1) {
          this.selecionarProduto(this.faixa.produtos[0]);
          this.proximaEtapa();
        }
      });
  }

  carregarProdutos(faixaKit: FaixaKitEntity) {
    this.loadingGestores<ProdutoKitEntity[]>(this.produtoService, "consultarProdutoKit", [faixaKit.codigoFaixaKit])
      .pipe(take(1))
      .subscribe(resp => {

        faixaKit.produtos = resp;
      });
  }

  solicitarKit() {
    try {
      this.textoBotao = "Processando...";
      this.processando = true;
      this.listaProdutos.push(this.produtoSelecionado);
      let result = new ItemCarrinhoEntity;
      result.quantidade = 1;
      result.isKit = true;
      result.codigoProdutoKit = this.produtoSelecionado.codigoProdutoKit;
      result.codigoConfiguracaoKit = this.configSelecionada.codigoConfiguracaoKit;
      result.produtosKit = this.listaProdutos;

      result.produto = new ProdutoEntity();
      result.produto.nomeProduto = this.configSelecionada.descricao;
      result.produto.nomeDivisao = " - ";
      result.produto.valorUnitario = this.listaProdutos.reduce((val, prod) => { return val + prod.valorProduto }, 0);
      result.produto.urlImagemKit = this.imgResultado;
      result.produto.estoque = 1;
      result.produto.idCliente = this.session.empresa.idCliente;

      let resultadoKit: any = document.getElementById('resultadoKit');

      //Tira um print do resultado final do arquivo e salva no objeto
      html2canvas(resultadoKit, { allowTaint: true, useCORS: true }).then((canvas) => {
        try {
          result.produto.imagemKit = canvas.toDataURL();

          this.produtoSelecionado.valorProduto = this.listaProdutos.reduce((val, prod) => { return val + prod.valorProduto }, 0);
          this.itensCarrinho = this.carrinho.itens;
          this.itensCarrinho.push(result);
          this.carrinho.itens = this.itensCarrinho;
          this.router.navigate(['/pedidos/finalizarpedido']);
        }
        catch (ex) {
          this.textoBotao = "Solicitar Kit";
          this.processando = false;
          this.alert.warning("Não foi possível salvar a imagem do Kit. Verifique se as imagens estão no mesmo domínio da aplicação. Em caso de dúvidas contate o administrador do sistema.");
          html2canvas(resultadoKit).then((canvas) => {
            result.produto.imagemKit = canvas.toDataURL();

            this.produtoSelecionado.valorProduto = this.listaProdutos.reduce((val, prod) => { return val + prod.valorProduto }, 0);
            this.itensCarrinho = this.carrinho.itens;
            this.itensCarrinho.push(result);
            this.carrinho.itens = this.itensCarrinho;
            this.router.navigate(['/pedidos/finalizarpedido']);
          });
        }
      });
    } catch (ex) {
      this.textoBotao = "Solicitar Kit";
      this.processando = false;
      this.alert.warning("Não foi possível salvar a imagem do Kit. Verifique se as imagens estão no mesmo domínio da aplicação. Em caso de dúvidas contate o administrador do sistema.");
    }
  }

  getTotalProdutos(faixa: FaixaKitEntity): number {
    let total = 0;
    if (!faixa.produtos)
      return 0;

    for (var x = 0; x < faixa.produtos.length; x++) {
      if (faixa.produtos[x].quantidade)
        total += Number(faixa.produtos[x].quantidade);
    }

    return total;
  }

  selecionarProduto(produto: ProdutoKitEntity) {
    if (produto.iniciaFluxo)
      this.codigoProdutoBase = produto.codigoProdutoKit;

    this.faixa.imgProdutoSelecionado = `${produto.imagensProduto[0]}`;
    this.faixasKit[this.faixaAtual].imgProdutoSelecionado = `${produto.imagensProduto[0]}`;
    this.codigoPaiSelecionado = produto.codigoProdutoKit;
    this.produtoSelecionado = produto;
    this.produtoSelecionado.idCliente = this.session.empresa.idCliente;
  }

  proximaEtapa() {
    if (!this.produtoSelecionado) {
      this.alert.warning(this.faixa.descricao);
      return;
    }
    this.faixaAtual++;
    this.faixa = { ...this.faixasKit[this.faixaAtual] };
    this.listaProdutos.push({ ...this.produtoSelecionado });
    this.consultarProdutosFilhos();
    this.produtoSelecionado = null;

    if (this.faixa.produtos.length === 1) {
      this.selecionarProduto(this.faixa.produtos[0]);
      this.proximaEtapa();
    }
  }

  etapaAnterior() {

    this.faixasKit[this.faixaAtual].imgProdutoSelecionado = null;
    this.faixaAtual--;
    this.faixa = { ...this.faixasKit[this.faixaAtual] };
    this.listaProdutos.splice(this.listaProdutos.length - 1, 1);
    this.faixasKit[this.faixaAtual].imgProdutoSelecionado = null;
    //this.consultarProdutosFilhos();
  }

  consultarProdutosFilhos() {

    let faixaAnterior = this.faixaAtual > 0 ? this.faixasKit[this.faixaAtual - 1].codigoFaixaKit : 0;
    let produto = this.produtoSelecionado.codigoProdutoKit;

    this.loadingGestores<ProdutoKitEntity[]>(this.produtoService, "consultarProdutosKitProdutoSelecionado", [this.faixa.codigoFaixaKit, produto, faixaAnterior])
      .pipe(take(1))
      .subscribe(resp => {
        this.faixa.produtos = resp;
      });
  }
}
