import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { CadastroService } from '../../cadastro/cadastro.service';
import { CategoriaProdutoEntity } from '../../cadastro/entities/categoria-produto.entity';
import { ProdutoEntity } from '../../entities/produto.entity';

import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { TagProdutoEntity } from '../entities/tag-produto.emtity';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-tags',
  templateUrl: './produto-tags.component.html',
  styleUrls: ['./produto-tags.component.css']
})
export class ProdutoTagsComponent extends ComponentBase implements OnInit {
  produtoSelecionado: ProdutoEntity
  categorias: CategoriaProdutoEntity[] = [];
  tagsSelecionadas: any[] = [];
  modoEdicao: boolean = false;

  constructor(
    private produtoService: ProdutoService,
    private session: SessionService,
    private cadastroService: CadastroService,
    private toastr: ToastrService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    // if (!this.session.empresa) {
    //   this.alert.error("Selecione uma empresa para continuar");
    //   this.session.urlReturn = window.location.href;
    //   window.location.href = '/empresa/selecionar';

    //   return;
    // }
    this.consultarCategorias();
  }

  consultarCategorias() {
    this.loading2<CategoriaProdutoEntity[]>(() => this.cadastroService.consultarCategorias("Tag", 1))
      .pipe(take(1))
      .subscribe(resp => {
        this.categorias = resp.filter(x => x.isTag && x.idCategoriaPai);
      });
  }

  onAddRemove(produto: ProdutoEntity) {
    produto.selecionado = false;
    this.produtoSelecionado = produto;

    this.loading2<TagProdutoEntity[]>(() => this.produtoService.consultarTagsPorProduto(produto.idInterno))
      .pipe(take(1))
      .subscribe(resp => {
        this.tagsSelecionadas = resp.map(x => x.idTag);
        this.modoEdicao = true;
      });
  }

  addTag(obj: any) {
    const entity = new TagProdutoEntity();
    entity.idProduto = this.produtoSelecionado.idInterno;
    entity.idTag = obj.idCategoria;
    entity.idCliente = 1;//this.session.empresa.idCliente;

    this.loading2(() => this.produtoService.inserirTagProduto(entity))
      .pipe(take(1))
      .subscribe(resp => {
        this.toastr.success("Características Adicionais adicionada com sucesso", "Adicionar Características Adicionais");
      });
  }

  removeTag(obj: any) {
    const entity = new TagProdutoEntity();
    entity.idProduto = this.produtoSelecionado.idInterno;
    entity.idTag = obj.value.idCategoria;
    entity.idCliente = 1;//this.session.empresa.idCliente;

    this.loading2(() => this.produtoService.excluirTagProduto(entity))
      .pipe(take(1))
      .subscribe(resp => {
        this.toastr.success("Características Adicionais removida com sucesso", "Remover Características Adicionais");
      });
  }

  finalizar() {
    this.modoEdicao = false;
  }
}
