export class InserirNotaFiscalEntradaRequestEntity {
  idNotaFiscal: number;
  numeroNotaFiscal: string;
  serie: string;
  dataNotaFiscal: string;
  idCliente: number;
  valorNotaFiscal: number;
  idDestinatario: number;
  codigoFiscal: string;
  descricaoFiscal: string;
  comentarioCodigoFiscal: string;
  nomeUsuario: string;
}
