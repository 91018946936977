import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DivisaoESLEntity } from '../entities/divisao-esl.entity';
import { ServiceBase } from '../shared/service-base';
import { SessionService } from '../shared/session.service';
import { DivisaoEntity } from '../cadastro/entities/divisao.entity';

@Injectable({
  providedIn: 'root'
})
export class DivisaoService extends ServiceBase {

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) { super(); }

  consultarDivisaoEmpresa(codigoCliente: number, somenteComProdutosEmEstoque: boolean = false): Observable<DivisaoESLEntity[]> {
    return this.http.get<DivisaoESLEntity[]>(`${environment.baseUrl}api/divisao/ConsultarDivisaoEmpresa/${codigoCliente}/${somenteComProdutosEmEstoque}`);
  }

  listarAgrupada(): Observable<DivisaoEntity[]> {
    return this.http.get<DivisaoEntity[]>(`${environment.baseUrl}api/v2/divisao/agrupada`);
  }

  atualizarDivisaoUtilizaSaldoVirtual(divisao: DivisaoEntity): Observable<any> {
    return this.http.put(`${environment.baseUrl}api/v2/divisao/saldo-virtual`, divisao);
  }

  pesquisarDivisoesUsuario(idProduto: number, somenteComProdutosEmEstoque:boolean = false): Observable<DivisaoEntity[]> {
    return this.http.get<DivisaoEntity[]>(`${environment.baseUrl}api/v2/divisao/pesquisar-divisoes-usuario/${idProduto}/?somenteComSaldo=${somenteComProdutosEmEstoque}`);
  }

}
