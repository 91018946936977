import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProdutoEntity } from '../../entities/produto.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-relacionado',
  templateUrl: './produto-relacionado.component.html',
  styleUrls: ['./produto-relacionado.component.css']
})
export class ProdutoRelacionadoComponent extends ComponentBase implements OnInit {

  @Output() onProdutoSelecionado: EventEmitter<ProdutoEntity> = new EventEmitter();

  constructor(
    private produtoService: ProdutoService,
    public session: SessionService
  ) {
    super();
  }

  ngOnInit() {
  }

  detail(produto: ProdutoEntity) {
    this.onProdutoSelecionado.emit(produto);
  }

  getImagemProduto(codigoProduto: string): string {
    return this.produtoService.getImagemProduto(codigoProduto);
  }
}
