import { Injectable } from '@angular/core';

import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() { }

  info(message: string): Promise<any> {
    return swal.fire({
      text: message,
      icon: "info"
    });
  }

  success(message: string): Promise<any> {
    return swal.fire({
      text: message,
      icon: "success"
    });
  }

  warning(message: string): Promise<any> {
    return swal.fire({
      title: "Atenção!",
      text: message,      
      icon: "warning",
    
    });
  }

  error(message: string, errorCode?: number): Promise<any> {
    let title = 'Erro';
    if (errorCode)
      title += ` ${errorCode}`;

    return swal.fire({
      title: `${title}!`,
      text: message,
      icon: "error"
    });
  }

  confirm(message: string): Promise<any> {
    return swal.fire({
      text: message,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',

    });
  }
}
